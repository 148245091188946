/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import logo from "../assets/images/logo.png";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

const Footer = (props) => {
  const {
    showLogo,
    showCopyRightBar,
    showCompanyDetail,
    showRoutes,
    showCopyrightLogo,
  } = props;
  //Aos

  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);

  const exclusionArray = [
    "/pages/doctor-grid",
    "/pages/doctor-list",
    "/pages/video-call",
    "/pages/voice-call",
    "/pages/chat-doctor",
    "/patient/doctor-list",
    "/patient/doctor-grid",
  ];
  if (exclusionArray.indexOf(props.location.pathname) >= 0) {
    return "";
  }

  return (
    <>
      {!props.location.pathname.includes("/home6") &&
        !props.location.pathname.includes("/home7") &&
        !props.location.pathname.includes("/paediatrichome") &&
        !props.location.pathname.includes("/home6") &&
        !props.location.pathname.includes("/home7") &&
        !props.location.pathname.includes("/home8") && (
          <footer
            className="footer footer-one"
            // style={{ padding: !showCopyRightBar ? 0 : 50 }}
          >
            <div className="footer-top">
              <div className="container">
                <div className="row">
                  <div className="col-lg-3 col-md-4">
                    <div className="footer-widget footer-about">
                      {showLogo ? null : (
                        <div className="footer-logo">
                          <img src={logo} alt="logo" />
                        </div>
                      )}
                      {showCompanyDetail ? null : (
                        <div className="footer-about-content">
                          <p>
                            The healthcare system Ecosystem to Make Healthcare
                            More Reasonable and Easily Available.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  {showRoutes ? null : (
                    <>
                      <div className="col-lg-2 col-md-4">
                        <div className="footer-widget footer-menu">
                          <h2 className="footer-title">For Patients</h2>
                          <ul>
                            <li>Search for Doctors</li>
                            <li>Login</li>
                            <li>Register</li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4">
                        <div className="footer-widget footer-menu">
                          <h2 className="footer-title">For Doctors</h2>
                          <ul>
                            <li>
                              <a>Appointments</a>
                            </li>
                            <li>
                              <a>Chat</a>
                            </li>
                            <li>
                              <a to="/login">Login</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-5">
                        <div className="footer-widget footer-contact">
                          <h2 className="footer-title">Contact Us</h2>
                          <div className="footer-contact-info">
                            <div className="footer-address">
                              <p>
                                <i>
                                  <FeatherIcon
                                    icon="phone-call"
                                    style={{ width: "16px", height: "16px" }}
                                  />
                                </i>{" "}
                                +91 9902842628
                              </p>
                            </div>
                            <div className="footer-address mb-0">
                              <p>
                                <i>
                                  <FeatherIcon
                                    icon="mail"
                                    style={{ width: "16px", height: "16px" }}
                                  />
                                </i>{" "}
                                info@certumventures.in
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-7">
                        <div className="footer-widget">
                          <h2 className="footer-title">Join Our Newsletter</h2>

                          <div className="social-icon">
                            <ul>
                              <li>
                                <Link to="#" target="_blank">
                                  <i className="fab fa-facebook" />{" "}
                                </Link>
                              </li>
                              <li>
                                <Link to="#" target="_blank">
                                  <i className="fab fa-instagram" />
                                </Link>
                              </li>
                              <li>
                                <Link to="#" target="_blank">
                                  <i className="fab fa-twitter" />{" "}
                                </Link>
                              </li>
                              <li>
                                <a
                                  href="https://in.linkedin.com/company/certum-venture-pvt-ltd"
                                  target="blank"
                                >
                                  <i className="fab fa-linkedin-in" />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {showCopyRightBar ? null : (
              <div className="footer-bottom">
                <div className="container">
                  {/* Copyright */}
                  <div className="copyright">
                    {showCopyrightLogo ? (
                      <div className="navbar-header">
                        <Link to="/index" className="navbar-brand logo">
                          <img src={logo} alt="logo" className="img-fluid" />
                        </Link>
                      </div>
                    ) : null}
                    <div className="row">
                      <div className="col-md-6 col-lg-6">
                        <div className="copyright-text">
                          <p className="mb-0">
                            {" "}
                            Copyright © 2015{" "}
                            <a href="https://certumventures.in/" target="blank">
                              certumventures.
                            </a>{" "}
                            All Rights Reserved
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-6">
                        {/* Copyright Menu */}
                        <div className="copyright-menu">
                          <ul className="policy-menu">
                            <li>
                              <Link to="/privacy-policy">Privacy Policy</Link>
                            </li>
                            <li>
                              <Link to="/terms">Terms and Conditions</Link>
                            </li>
                          </ul>
                        </div>
                        {/* /Copyright Menu */}
                      </div>
                    </div>
                  </div>
                  {/* /Copyright */}
                </div>
              </div>
            )}
          </footer>
        )}
    </>
  );
};

export default Footer;
