import React, { useEffect, useState } from "react";
import DashboardSidebar from "../dashboard/sidebar/sidebar.jsx";

import StickyBox from "react-sticky-box";
import { Link } from "react-router-dom";
import Header from "../../header";
import Footer from "../../footer";
import { IMG01 } from "../doctorprofile/img.jsx";
import { getPharmacyBillDetails } from "../../../../apis/ReportsAndBills.js";
// import { useAppData } from "../../../../hooks/AppDataContext.jsx";

const Bills = (props) => {
  // const { appData } = useAppData();
  const user = JSON.parse(localStorage.getItem("user"));
  const [data, setData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const data = {
        patient_id: user?.id,
      };

      const req = await getPharmacyBillDetails(data);
      console.log(req);
      setData(req);
    }
    fetchData();
  }, []);
  return (
    <div>
      <Header {...props} />
      <div className="breadcrumb-bar-two">
        <div className="container">
          <div className="row align-items-center inner-banner">
            <div className="col-md-12 col-12 text-center">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <h2 className="breadcrumb-title">Medical Details</h2>

                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/index">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Pharmacy Bills
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
              <StickyBox offsetTop={20} offsetBottom={20}>
                <DashboardSidebar />
              </StickyBox>
            </div>
            <div className="col-md-7 col-lg-8 col-xl-9">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title float-start">Pharmacy Bills</h4>
                    </div>

                    <div className="card card-table mb-0">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table table-hover table-center mb-0">
                            <thead>
                              <tr>
                                <th>Invoice No</th>
                                <th>Doctor</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.length > 0
                                ? data?.map((item, index) => (
                                    <tr key={index}>
                                      <td>
                                        <Link to="/pages/invoice-view">
                                          #INV-0010
                                        </Link>
                                      </td>
                                      <td>
                                        <h2 className="table-avatar">
                                          <Link className="avatar avatar-sm me-2">
                                            <img
                                              className="avatar-img rounded-circle"
                                              src={IMG01}
                                              alt="User "
                                            />
                                          </Link>
                                          <a>{item.doctorName}</a>
                                        </h2>
                                      </td>
                                      <td>Rs. {item.amount}</td>
                                      <td>{item.paymentStatus}</td>
                                      <td className="text-end">
                                        <div className="table-action">
                                          <Link
                                            to="/pages/invoice-view"
                                            className="btn btn-sm bg-info-light"
                                          >
                                            <i className="far fa-eye"></i> View
                                          </Link>
                                          &nbsp;
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                : null}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer {...props} />
    </div>
  );
};

export default Bills;
