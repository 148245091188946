import { apiphpClient } from "./configs/axiosConfig";

export const getPharmacyBillDetails = async (data) => {
  let responseToBeSent;
  await apiphpClient
    .post(`PatientPharmacy/pharmBillData`, data)
    .then((res) => {
      responseToBeSent = res.data.messages.Patient_pharmacybill.resultlist;
    })
    .catch((err) => {
      console.log("Error in get doc details ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};
