import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAppData } from "./hooks/AppDataContext";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { appData } = useAppData();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (appData?.user?.id) {
          return Component ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/index",
                state: {
                  from: props?.location,
                },
              }}
            />
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props?.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
