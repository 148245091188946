
export { default as IMG01} from '../../../assets/images/doctor-thumb-02.jpg';
export { default as IMG02} from '../../../assets/images/specialities-05.png';
export { default as IMG03} from '../../../assets/images/feature-01.jpg';
export { default as IMG04} from '../../../assets/images/feature-02.jpg';
export { default as IMG05} from '../../../assets/images/feature-03.jpg';
export { default as IMG06} from '../../../assets/images/feature-04.jpg';
export { default as IMG07} from '../../../assets/images/patients/patient.jpg';
export { default as IMG08} from '../../../assets/images/patient1.jpg';
export { default as IMG09} from '../../../assets/images/patient2.jpg';


