/* eslint-disable no-unused-vars */
/* eslint-disable no-constant-condition */
import React, { useState } from "react";
import { Link } from "react-router-dom";
// import { useHistory } from "react-router-dom";
// import { useLocation } from "react-router-dom";
import logo from "../assets/images/logo.png";
import IMG01 from "../assets/images/doctors/doctor-thumb-02.jpg";
// import Dropdown from "react-bootstrap/Dropdown";
import { useEffect } from "react";

import AOS from "aos";
import "aos/dist/aos.css";
import FeatherIcon from "feather-icons-react";

import Chart from "./patients/dashboard/chart";
import Notification from "./patients/dashboard/notification";
import { IMG07 } from "../components/patients/doctorprofile/img";
import { footer_logo } from "./home/image";
import { useAppData } from "../../hooks/AppDataContext";
import { getPatDetails } from "../../apis/ProfileApis";

const Header = (props) => {
  // const history = useHistory();
  //Aos
  // const location = useLocation();
  const { appData, updateAppData } = useAppData();
  const storedUser = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);

  const config = "/";

  //mobile menu
  const [change, setChange] = useState(false);
  const [isSideMenu, setSideMenu] = useState("");
  const [isSideMenuone, setSideMenuone] = useState("");
  const [isSideMenutwo, setSideMenutwo] = useState("");
  const [isSideSearch, setSideSearch] = useState("");
  const [isSidebooking, setSideBooking] = useState("");
  const [button, setButton] = useState(true);
  const [navbar, setNavbar] = useState(false);
  const [isSideMenuthree, setSideMenuthree] = useState("");
  const [isSideMenufour, setSideMenufour] = useState("");
  const [sideMenufive, setSideMenufive] = useState("");
  const [menu, setMenu] = useState(false);
  // const [menu1, setMenu1] = useState(false);
  const toggleSidebarthree = (value) => {
    setSideMenuthree(value);
  };
  const toggleSidebar = (value) => {
    setSideMenu(value);
  };
  const toggleSidebarfour = (value) => {
    setSideMenufour(value);
  };
  const toggleSidebarfive = (value) => {
    setSideMenufive(value);
  };
  const toggleSidebarone = (value) => {
    setSideMenuone(value);
  };
  const toggleSidebartwo = (value) => {
    setSideMenutwo(value);
  };
  const toggleSidebarsearch = (value) => {
    setSideSearch(value);
  };
  const toggleSidebarbooking = (value) => {
    setSideBooking(value);
  };

  // const mobilemenus = () => {
  //   setMenu(!true);
  // };

  // Rest of your code that uses pathnames

  let pathnames = window.location.pathname;

  // const [active, setActive] = useState(false);
  const url = pathnames.split("/").slice(0, -1).join("/");

  const onHandleMobileMenu = () => {
    var root = document.getElementsByTagName("html")[0];
    root.classList.add("menu-opened");
  };

  const onhandleCloseMenu = () => {
    var root = document.getElementsByTagName("html")[0];
    root.classList.remove("menu-opened");
  };

  //nav transparent

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);
  window.addEventListener("resize", showButton);

  const changeBackground = () => {
    if (window.scrollY >= 95) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeBackground);

  return (
    <>
      {!pathnames.includes("home1") && (
        <header
          className={`header header-fixed header-one`}
          style={{ background: "" }}
        >
          <div className="container">
            <nav
              className={`navbar navbar-expand-lg header-nav ${
                pathnames.includes("home1") ? "nav-transparent" : ""
              }`}
            >
              <div className="navbar-header">
                <Link
                  to="/index"
                  id="mobile_btn"
                  onClick={() => onHandleMobileMenu()}
                  style={{ marginTop: "50px" }}
                >
                  <span className="bar-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                </Link>
              </div>
              <div className="navbar-header">
                <Link to="/index" className="navbar-brand logo">
                  <img src={logo} className="img-fluid" alt="Logo" />
                </Link>
              </div>
              <div className="main-menu-wrapper">
                <div className="menu-header">
                  <Link to="/home" className="menu-logo">
                    <img src={logo} className="img-fluid" alt="Logo" />
                  </Link>
                  <Link
                    to="#0"
                    id="menu_close"
                    className="menu-close"
                    onClick={() => onhandleCloseMenu()}
                  >
                    <i className="fas fa-times"></i>
                  </Link>
                </div>

                <ul
                  className={`main-nav ${
                    pathnames.includes("home4") ? "white-font" : ""
                  }`}
                >
                  <li
                    className={`has-submenu ${
                      url.includes("/patient") ? "active" : ""
                    }`}
                  >
                    {pathnames?.includes("/patient") ||
                    pathnames?.includes("pharmacy-list") ||
                    pathnames?.includes("/uploadRx") ||
                    (pathnames?.includes("/index") && storedUser?.id) ? (
                      <Link
                        to="#"
                        className={isSideMenu == "patients" ? "subdrop" : ""}
                        onMouseEnter={() =>
                          toggleSidebar(
                            isSideMenu == "patients" ? "submenu" : "patients"
                          )
                        }
                      >
                        <span className="user-img">
                          <img
                            className="rounded-circle"
                            src={IMG07}
                            width="31"
                            alt="Darren Elder"
                          />
                        </span>{" "}
                        <i className="fas fa-chevron-down" />
                      </Link>
                    ) : null}
                    {isSideMenu == "patients" ? (
                      // <ul
                      //   className={`${
                      //     isSideMenu == "patients"
                      //       ? "submenu d-block"
                      //       : "submenu"
                      //   }`}
                      // >
                      //   <li
                      //     className={`has-submenu ${
                      //       pathnames.includes("doctor") &&
                      //       !pathnames.includes("doctor-profile") &&
                      //       !pathnames.includes("search-doctor")
                      //         ? "active"
                      //         : ""
                      //     }`}
                      //   >
                      //     <Link
                      //       to="#0"
                      //       className={
                      //         isSideMenutwo == "doctor" ? "subdrop" : ""
                      //       }
                      //       onClick={() =>
                      //         toggleSidebartwo(
                      //           isSideMenutwo == "doctor" ? "" : "doctor"
                      //         )
                      //       }
                      //     >
                      //       Doctors{" "}
                      //     </Link>
                      //     {isSideMenutwo == "doctor" ? (
                      //       <ul
                      //         className={
                      //           isSideMenutwo == "doctor"
                      //             ? "submenu d-block"
                      //             : "submenu"
                      //         }
                      //       >
                      //         <li
                      //           className={
                      //             pathnames.includes("doctor-grid")
                      //               ? "active"
                      //               : ""
                      //           }
                      //         >
                      //           <Link
                      //             to="/patient/doctor-grid"
                      //             onClick={() => onhandleCloseMenu()}
                      //           >
                      //             Map Grid
                      //           </Link>
                      //         </li>
                      //         <li
                      //           className={
                      //             pathnames.includes("doctor-list")
                      //               ? "active"
                      //               : ""
                      //           }
                      //         >
                      //           <Link
                      //             to="/patient/doctor-list"
                      //             onClick={() => onhandleCloseMenu()}
                      //           >
                      //             Map List
                      //           </Link>
                      //         </li>
                      //       </ul>
                      //     ) : (
                      //       ""
                      //     )}
                      //   </li>
                      //   <li
                      //     className={
                      //       pathnames.includes("search-doctor") ? "active" : ""
                      //     }
                      //   >
                      //     <Link
                      //       to="/patient/search-doctor1"
                      //       onClick={() => onhandleCloseMenu()}
                      //     >
                      //       Search Doctor
                      //     </Link>
                      //   </li>
                      //   <li
                      //     className={
                      //       pathnames.includes("doctor-profile") ? "active" : ""
                      //     }
                      //   >
                      //     <Link
                      //       to="/patient/doctor-profile"
                      //       onClick={() => onhandleCloseMenu()}
                      //     >
                      //       Doctor Profile
                      //     </Link>
                      //   </li>
                      //   <li
                      //     className={`has-submenu ${
                      //       pathnames.includes("booking") &&
                      //       !pathnames.includes("booking-success")
                      //         ? "active"
                      //         : ""
                      //     }`}
                      //   >
                      //     <Link
                      //       to="#0"
                      //       className={
                      //         isSidebooking == "booking" ? "subdrop" : ""
                      //       }
                      //       onClick={() =>
                      //         toggleSidebarbooking(
                      //           isSidebooking == "booking" ? "" : "booking"
                      //         )
                      //       }
                      //     >
                      //       Booking
                      //     </Link>
                      //     {isSidebooking == "booking" ? (
                      //       <ul
                      //         className={
                      //           isSidebooking == "booking"
                      //             ? "submenu d-block"
                      //             : "submenu"
                      //         }
                      //       >
                      //         <li
                      //           className={
                      //             pathnames.includes("booking1") ? "active" : ""
                      //           }
                      //         >
                      //           <Link
                      //             to="/patient/booking1"
                      //             onClick={() => onhandleCloseMenu()}
                      //           >
                      //             Booking 1
                      //           </Link>
                      //         </li>
                      //         <li
                      //           className={
                      //             pathnames.includes("booking2") ? "active" : ""
                      //           }
                      //         >
                      //           <Link
                      //             to="/patient/booking2"
                      //             onClick={() => onhandleCloseMenu()}
                      //           >
                      //             Booking 2
                      //           </Link>
                      //         </li>
                      //       </ul>
                      //     ) : (
                      //       ""
                      //     )}
                      //   </li>
                      //   {/* <li className={pathnames.includes("booking") && !pathnames.includes("booking-success") ? "active" : ""}>
                      //     <Link to="/patient/booking1" onClick={() => onhandleCloseMenu()}>Booking</Link>
                      //   </li> */}
                      //   <li
                      //     className={
                      //       pathnames.includes("checkout") ? "active" : ""
                      //     }
                      //   >
                      //     <Link
                      //       to="/patient/checkout"
                      //       onClick={() => onhandleCloseMenu()}
                      //     >
                      //       Checkout
                      //     </Link>
                      //   </li>
                      //   <li
                      //     className={
                      //       pathnames.includes("booking-success")
                      //         ? "active"
                      //         : ""
                      //     }
                      //   >
                      //     <Link
                      //       to="/patient/booking-success"
                      //       onClick={() => onhandleCloseMenu()}
                      //     >
                      //       Booking Success
                      //     </Link>
                      //   </li>
                      //   <li
                      //     className={
                      //       pathnames.includes("dashboard") ? "active" : ""
                      //     }
                      //   >
                      //     <Link
                      //       to="/patient/dashboard"
                      //       onClick={() => onhandleCloseMenu()}
                      //     >
                      //       Patient Dashboard
                      //     </Link>
                      //   </li>
                      //   <li
                      //     className={
                      //       pathnames.includes("favourites") ? "active" : ""
                      //     }
                      //   >
                      //     <Link
                      //       to="/patient/favourites"
                      //       onClick={() => onhandleCloseMenu()}
                      //     >
                      //       Favourites
                      //     </Link>
                      //   </li>
                      //   <li
                      //     className={
                      //       pathnames.includes("patient-chat") ? "active" : ""
                      //     }
                      //   >
                      //     <Link
                      //       to="/patient/patient-chat"
                      //       onClick={() => onhandleCloseMenu()}
                      //     >
                      //       Chat
                      //     </Link>
                      //   </li>
                      //   <li
                      //     className={
                      //       pathnames.includes("profile") &&
                      //       !pathnames.includes("doctor-profile")
                      //         ? "active"
                      //         : ""
                      //     }
                      //   >
                      //     <Link
                      //       to="/patient/profile"
                      //       onClick={() => onhandleCloseMenu()}
                      //     >
                      //       Profile Settings
                      //     </Link>
                      //   </li>
                      //   <li
                      //     className={
                      //       pathnames.includes("change-password")
                      //         ? "active"
                      //         : ""
                      //     }
                      //   >
                      //     <Link
                      //       to="/patient/change-password"
                      //       onClick={() => onhandleCloseMenu()}
                      //     >
                      //       Change Password
                      //     </Link>
                      //   </li>
                      // </ul>
                      <ul
                        className={`${
                          isSideMenu == "patients"
                            ? "submenu d-block"
                            : "submenu"
                        }`}
                      >
                        <li
                          className={
                            pathnames.includes("/patient/bills") ? "active" : ""
                          }
                        >
                          <div className="card-header">
                            <div className="dropdown-item user-header">
                              <div className="avatar avatar-sm">
                                <img
                                  src={IMG07}
                                  alt="User Image"
                                  className="avatar-img rounded-circle"
                                />
                              </div>
                              <div className="user-text">
                                <h6>{`${appData?.user?.patient_name} ${appData?.user?.patient_lname}`}</h6>
                                <p className="text-muted mb-0">Patient</p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li
                          className={
                            pathnames.includes("/patient/medicaldetails")
                              ? "active"
                              : ""
                          }
                        >
                          <Link
                            to="/patient/medicaldetails"
                            onClick={() => onhandleCloseMenu()}
                          >
                            Your Prescriptions
                          </Link>
                        </li>
                        {/* <li
                          className={
                            pathnames.includes("/patient/bills") ? "active" : ""
                          }
                        >
                          <Link
                            to="/patient/bills"
                            onClick={() => onhandleCloseMenu()}
                          >
                            Your Bills
                          </Link>
                        </li> */}
                      </ul>
                    ) : (
                      ""
                    )}
                  </li>

                  {pathnames.includes("/login") ||
                  pathnames.includes("/register") ||
                  pathnames.includes("/patient/search-doctor1") ||
                  pathnames.includes("/aboutus") ||
                  pathnames.includes("/home3") ||
                  (pathnames.includes("/index") && !storedUser?.id) ? (
                    <>
                      <li className="login-link">
                        <Link to="/login">Login / Signup</Link>
                      </li>
                      <li className="register-btn">
                        <Link to="/register" className="btn reg-btn">
                          <i>
                            <FeatherIcon icon="user" />
                          </i>
                          Register
                        </Link>
                      </li>
                      <li className="register-btn">
                        <Link to="/login" className="btn btn-primary log-btn">
                          <i>
                            <FeatherIcon icon="lock" />
                          </i>
                          Login
                        </Link>
                      </li>
                    </>
                  ) : null}
                </ul>
              </div>
            </nav>
          </div>
        </header>
      )}
    </>
  );
};

export default Header;
