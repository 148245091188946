export { default as doctorthumb02 } from "../assets/images/doctors/doctor-thumb-02.jpg";
export { default as coming_soon } from "../assets/images/coming-soon.png";
export { default as smiling_icon } from "../assets/images/icons/smiling-icon.svg";
export { default as partners_1 } from "../assets/images/partners/partners-1.svg";
export { default as partners_2 } from "../assets/images/partners/partners-2.svg";
export { default as partners_3 } from "../assets/images/partners/partners-3.svg";
export { default as partners_4 } from "../assets/images/partners/partners-4.svg";
export { default as partners_5 } from "../assets/images/partners/partners-5.svg";
// export { default as partners_6 } from "../assets/images/partners/partners-6.png";
export { default as specialities_01 } from "../assets/images/specialities/specialities-01.svg";
export { default as specialities_02 } from "../assets/images/specialities/specialities-02.svg";
export { default as specialities_03 } from "../assets/images/specialities/specialities-03.svg";
export { default as specialities_04 } from "../assets/images/specialities/specialities-04.svg";
export { default as specialities_05 } from "../assets/images/specialities/specialities-05.svg";
export { default as specialities_06 } from "../assets/images/specialities/specialities-06.svg";
export { default as specialities_07 } from "../assets/images/specialities/specialities-07.svg";
export { default as specialities_08 } from "../assets/images/specialities/specialities-08.svg";
export { default as specialities_09 } from "../assets/images/specialities/specialities-09.svg";

export { default as client_01 } from "../assets/images/clients/client-01.jpg";
export { default as client_02 } from "../assets/images/clients/client-02.jpg";
export { default as client_03 } from "../assets/images/clients/client-03.jpg";
export { default as client_04 } from "../assets/images/clients/client-04.jpg";
export { default as client_05 } from "../assets/images/clients/client-05.jpg";
export { default as work_img } from "../assets/images/work-img.png";
export { default as work_04 } from "../assets/images/icons/clinic.svg";
export { default as work_03 } from "../assets/images/icons/specCare.svg";
export { default as work_02 } from "../assets/images/icons/doctor.svg";
export { default as work_01 } from "../assets/images/icons/preventiveCare.svg";
export { default as home_11 } from "../assets/images/home-11.jpg";
export { default as home_10 } from "../assets/images/home-10.jpg";
export { default as home_09 } from "../assets/images/home-09.jpg";
export { default as home_08 } from "../assets/images/home-08.jpg";
export { default as home_07 } from "../assets/images/home-07.jpg";
export { default as home_06 } from "../assets/images/home-06.jpg";
export { default as home_05 } from "../assets/images/home-05.jpg";
export { default as home_04 } from "../assets/images/home-04.jpg";
export { default as home_03 } from "../assets/images/home-03.jpg";
export { default as home_02 } from "../assets/images/home-02.jpg";
export { default as home_01 } from "../assets/images/home-01.jpg";

export { default as error404 } from "../assets/images/error-404.png";
export { default as error500 } from "../assets/images/error-500.png";
export { default as maintenance } from "../assets/images/maintenance.png";
export { default as price_icon1 } from "../assets/images/icons/price-icon1.svg";
export { default as price_icon2 } from "../assets/images/icons/price-icon2.svg";
export { default as price_icon3 } from "../assets/images/icons/price-icon3.svg";
export { default as header_icon } from "../assets/images/icons/header-icon.svg";
export { default as down_arrow_img } from "../assets/images/down-arrow-img.png";
export { default as banner_img } from "../assets/images/banner-img.png";
export { default as home5banner_img } from "../assets/images/bg/home-10-banner-img.png";
export { default as generalbanner_img } from "../assets/images/banner-img.png";
export { default as banner_img1 } from "../assets/images/banner-img1.png";
export { default as banner_img2 } from "../assets/images/banner-img2.png";
export { default as banner_img3 } from "../assets/images/banner-img3.png";
export { default as scan_img } from "../assets/images/scan-img.png";
export { default as google_play } from "../assets/images/google-play.png";
export { default as app_store } from "../assets/images/app-store.png";
export { default as mobile_img } from "../assets/images/mobile-img.png";
export { default as blog_11 } from "../assets/images/blog/blog-11.jpg";
export { default as blog_12 } from "../assets/images/blog/blog-12.jpg";
export { default as blog_13 } from "../assets/images/blog/blog-13.jpg";
export { default as blog_14 } from "../assets/images/blog/blog-14.jpg";
export { default as blog_15 } from "../assets/images/blog/blog-15.jpg";
export { default as blog_16 } from "../assets/images/blog/blog-16.jpg";
export { default as blog_17 } from "../assets/images/blog/blog-17.jpg";

export { default as re_image_1 } from "../assets/images/clients/re-image-1.jpg";
export { default as re_image_2 } from "../assets/images/clients/re-image-2.jpg";
export { default as re_image_3 } from "../assets/images/clients/re-image-3.jpg";
export { default as re_image_4 } from "../assets/images/clients/re-image-4.jpg";
export { default as re_image_5 } from "../assets/images/clients/re-image-5.jpg";
export { default as re_image_6 } from "../assets/images/clients/re-image-6.jpg";
export { default as re_image_7 } from "../assets/images/clients/re-image-7.jpg";
export { default as re_image_8 } from "../assets/images/clients/re-image-8.jpg";
export { default as re_image_9 } from "../assets/images/clients/re-image-9.jpg";

export { default as clinic_011 } from "../assets/images/clients/clinic-011.png";
export { default as clinic_012 } from "../assets/images/clients/clinic-012.png";
export { default as clinic_013 } from "../assets/images/clients/clinic-013.png";
export { default as clinic_014 } from "../assets/images/clients/clinic-014.png";
export { default as clinic_015 } from "../assets/images/clients/clinic-015.png";

export { default as heart_bg } from "../assets/images/bg/heart-bg.png";
// export { default as health_care_1 } from "../assets/images/icons/health-care-1.png";
export { default as heart_2 } from "../assets/images/icons/heart-2.svg";
export { default as healthcare } from "../assets/images/bg/health-care.png";
export { default as healthcarelove } from "../assets/images/icons/health-care-love.svg";
export { default as userdoctor } from "../assets/images/icons/user-doctor.svg";
export { default as health } from "../assets/images/icons/healthcare.svg";
export { default as drugssvg } from "../assets/images/icons/drugs-svg.svg";
export { default as syringesvg } from "../assets/images/icons/syringe-svg.svg";
export { default as feature_07 } from "../assets/images/features/feature-07.jpg";
export { default as feature_08 } from "../assets/images/features/feature-08.jpg";
export { default as feature_09 } from "../assets/images/features/feature-09.jpg";
export { default as feature_10 } from "../assets/images/features/feature-10.jpg";
export { default as feature_11 } from "../assets/images/features/feature-11.jpg";
export { default as feature_12 } from "../assets/images/features/feature-12.jpg";
export { default as feature_13 } from "../assets/images/features/feature-13.jpg";
export { default as feature_14 } from "../assets/images/features/feature-14.jpg";
export { default as feature_15 } from "../assets/images/features/feature-15.jpg";
export { default as feature_16 } from "../assets/images/features/feature-16.jpg";

export { default as doctor_13 } from "../assets/images/doctors/doctor-13.jpg";
export { default as doctor_19 } from "../assets/images/doctors/doctor-19.jpg";
export { default as doctor_20 } from "../assets/images/doctors/doctor-20.jpg";
export { default as doctor_21 } from "../assets/images/doctors/doctor-21.jpg";
export { default as doctor_22 } from "../assets/images/doctors/doctor-22.jpg";
export { default as doctor_23 } from "../assets/images/doctors/doctor-23.jpg";
export { default as doctor_24 } from "../assets/images/doctors/doctor-24.jpg";

export { default as hexagon_group_1 } from "../assets/images/bg/hexagen-group-1.png";
export { default as hexagon_group_2 } from "../assets/images/bg/hexagon-group-2.png";
export { default as hexagon_group_3 } from "../assets/images/bg/hexagen-group-3.png";
export { default as hexagon_group_4 } from "../assets/images/bg/hexagen-group-4.png";
export { default as hexagon_group_5 } from "../assets/images/bg/hexagen-group-5.png";

export { default as hand } from "../assets/images/bg/hand.png";
export { default as heartplus } from "../assets/images/bg/heart-plus.png";
export { default as ecg } from "../assets/images/bg/ecg.png";

export { default as ecgwave } from "../assets/images/bg/ecg-wave.png";

export { default as doctor_thumb_07 } from "../assets/images/doctors/doctor-thumb-07.jpg";
export { default as heartpulse } from "../assets/images/icons/hreat-pulse.svg";

export { default as client_07 } from "../assets/images/clients/client-07.jpg";
export { default as client_08 } from "../assets/images/clients/client-08.jpg";
export { default as client_09 } from "../assets/images/clients/client-09.jpg";

export { default as faq_img } from "../assets/images/faq-img.png";
export { default as faq_img_2 } from "../assets/images/faq-img-2.jpg";
export { default as footerlogo } from "../assets/images/footer-logo.svg";
export { default as footermap } from "../assets/images/bg/footer-map.jpg";

export { default as uploadicon } from "../assets/images/upload-icon.png";

export { default as home_13_banner } from "../assets/images/bg/home-13-banner-img.png";

export { default as pulse_1 } from "../assets/images/bg/pluse-1.png";
export { default as pulse_2 } from "../assets/images/bg/pluse-2.png";
export { default as pulse_3 } from "../assets/images/bg/pluse-3.png";
export { default as pulse_4 } from "../assets/images/bg/pluse-4.png";
export { default as pulse_6 } from "../assets/images/bg/pulse-6.png";
export { default as pulse_7 } from "../assets/images/bg/pulse-7.png";
export { default as pulse_white } from "../assets/images/bg/pluse-white.png";
export { default as star_1 } from "../assets/images/bg/star-1.png";
export { default as cloud_1 } from "../assets/images/bg/cloud-1.png";
export { default as cloud_2 } from "../assets/images/bg/cloud-2.png";
export { default as gold_star } from "../assets/images/bg/gold-star.png";
export { default as pink_star } from "../assets/images/bg/pink-star.png";
export { default as baby_star } from "../assets/images/bg/baby-star.png";
export { default as heart_1 } from "../assets/images/bg/heart-1.png";
export { default as atom_bond } from "../assets/images/bg/atom-bond.png";
export { default as doctor_bg } from "../assets/images/bg/doctor-bg.jpg";
export { default as rainbow_2 } from "../assets/images/bg/rainbow-2.png";
export { default as rainbow_3 } from "../assets/images/icons/rainbow-3.svg";
export { default as rainbow_4 } from "../assets/images/icons/rainbow-4.svg";

export { default as clientsays } from "../assets/images/bg/clients-says.jpg";

export { default as atom_bond2 } from "../assets/images/icons/atom-bond2.svg";
export { default as service_1 } from "../assets/images/clinic/service-1.jpg";
export { default as service_2 } from "../assets/images/clinic/service-2.jpg";
export { default as service_3 } from "../assets/images/clinic/service-3.jpg";
export { default as service_4 } from "../assets/images/clinic/service-4.jpg";
export { default as service_5 } from "../assets/images/clinic/service-5.jpg";

export { default as features_01 } from "../assets/images/features/feature-01.jpg";
export { default as doctor_25 } from "../assets/images/doctors/doctor-25.png";
export { default as doctor_26 } from "../assets/images/doctors/doctor-26.png";
export { default as doctor_27 } from "../assets/images/doctors/doctor-27.png";

export { default as articles_1 } from "../assets/images/patients/articles-1.jpg";
export { default as articles_2 } from "../assets/images/patients/articles-2.jpg";
export { default as articles_3 } from "../assets/images/patients/articles-3.jpg";
export { default as articles_4 } from "../assets/images/patients/articles-4.jpg";

export { default as feedback_inner } from "../assets/images/feedback-inner.png";
export { default as app_store_1 } from "../assets/images/app-store-1.png";
export { default as google_store_1 } from "../assets/images/google-store-1.png";

export { default as add_circle } from "../assets/images/icons/add-circle.png";

export { default as star } from "../assets/images/icons/star.svg";
export { default as eye_icon_01 } from "../assets/images/icons/eye-icon-01.svg";
export { default as eye_icon } from "../assets/images/icons/eye-icon.svg";
export { default as ban_six_icon1 } from "../assets/images/icons/ban-six-icon1.svg";
export { default as ban_six_icon3 } from "../assets/images/icons/ban-six-icon3.svg";
export { default as ban_six_icon2 } from "../assets/images/icons/ban-six-icon2.svg";
export { default as ban_six_icon4 } from "../assets/images/icons/ban-img-icon-4.svg";
export { default as ban_six_icon5 } from "../assets/images/icons/ban-img-icon5.svg";
export { default as ban_six_icon6 } from "../assets/images/icons/ban-img-icon6.svg";

export { default as faq_01 } from "../assets/images/faq/faq-01.jpg";

// export {default as clinic_01} from '../assets/images/clinic/clinic-01.png'
// export {default as clinic_02} from '../assets/images/clinic/clinic-02.png'
// export {default as clinic_03} from '../assets/images/clinic/clinic-03.png'
// export {default as clinic_04} from '../assets/images/clinic/clinic-04.png'
// export {default as clinic_05} from '../assets/images/clinic/clinic-05.png'
// export {default as clinic_06} from '../assets/images/clinic/clinic-06.png'
// export {default as clinic_07} from '../assets/images/clinic/clinic-07.png'
// export {default as clinic_08} from '../assets/images/clinic/clinic-08.png'
// export {default as clinic_09} from '../assets/images/clinic/clinic-09.png'
// export {default as clinic_10} from '../assets/images/clinic/clinic-10.png'

export { default as banner_11 } from "../assets/images/banner-11.png";
export { default as eye } from "../assets/images/eye.png";
export { default as hospital } from "../assets/images/hospital.png";
export { default as offer_01 } from "../assets/images/offer-01.png";
export { default as offer_02 } from "../assets/images/offer-02.png";
export { default as testimonial } from "../assets/images/testimonial.jpg";
export { default as payment } from "../assets/images/payment.png";
export { default as logo_03 } from "../assets/images/logo-03.png";
export { default as logo_white } from "../assets/images/logo-white.svg";

export { default as banner_six_2 } from "../assets/images/banner-six-2.png";
export { default as services_six_1 } from "../assets/images/services-six-1.png";
export { default as service_six_1 } from "../assets/images/service-six-1.jpg";
export { default as service_six_2 } from "../assets/images/service-six-2.jpg";
export { default as service_six_3 } from "../assets/images/service-six-3.jpg";
export { default as service_six_4 } from "../assets/images/service-six-4.jpg";
export { default as choose_us_six } from "../assets/images/choose-us-six.png";
export { default as feedback_six } from "../assets/images/feedback-six.png";
export { default as contact_fourteen } from "../assets/images/contact-fourteen.png";
export { default as contact_icon1 } from "../assets/images/icons/contact-icon1.svg";
export { default as contact_icon2 } from "../assets/images/contact-icon2.png";
export { default as contact_icon3 } from "../assets/images/contact-icon3.png";
export { default as logo } from "../assets/images/logo.png";
export { default as logo_svg } from "../assets/images/logo.svg";
export { default as logo_15 } from "../assets/images/logo-15.png";

// export {default as eye_icon} from '../assets/images/icons/eye-icon.svg'
export { default as doc_01 } from "../assets/images/icons/doc-01.svg";
export { default as doc_02 } from "../assets/images/icons/doc-02.svg";
export { default as doc_03 } from "../assets/images/icons/doc-03.svg";
export { default as count_01 } from "../assets/images/icons/count-01.svg";
export { default as count_02 } from "../assets/images/icons/count-02.svg";
export { default as count_03 } from "../assets/images/icons/count-03.svg";
export { default as count_04 } from "../assets/images/icons/count-04.svg";
export { default as eye_white_icon } from "../assets/images/icons/eye-white-icon.svg";
export { default as serv_img_icon_1 } from "../assets/images/icons/serv-img-icon-1.svg";
export { default as serv_img_icon_2 } from "../assets/images/icons/serv-img-icon-2.svg";
export { default as serv_img_icon_3 } from "../assets/images/icons/serv-img-icon-3.svg";
export { default as choose_icon1 } from "../assets/images/icons/choose-icon1.svg";
export { default as choose_icon2 } from "../assets/images/icons/choose-icon2.svg";
export { default as choose_icon3 } from "../assets/images/icons/choose-icon3.svg";
export { default as choose_icon4 } from "../assets/images/icons/choose-icon4.svg";

export { default as ban_bg_01 } from "../assets/images/bg/ban-bg-01.png";
export { default as ban_bg_02 } from "../assets/images/bg/ban-bg-02.png";
export { default as center_bg } from "../assets/images/bg/center-bg.png";
export { default as counter_bg } from "../assets/images/bg/counter-bg.png";
export { default as counter_bg_01 } from "../assets/images/bg/counter-bg-01.png";
export { default as clinic_bg_01 } from "../assets/images/bg/clinic-bg-01.png";
export { default as store_bg_01 } from "../assets/images/bg/store-bg-01.png";
export { default as appoint_bg } from "../assets/images/bg/appoint-bg.png";

export { default as doctor_01 } from "../assets/images/doctors/doctor-01.jpg";
export { default as doctor_02 } from "../assets/images/doctors/doctor-02.jpg";
export { default as doctor_03 } from "../assets/images/doctors/doctor-03.jpg";
export { default as doctor_04 } from "../assets/images/doctors/doctor-04.jpg";
export { default as doctor_05 } from "../assets/images/doctors/doctor-05.jpg";

export { default as doctor_08 } from "../assets/images/doctors/doctor-08.jpg";
export { default as doctor_09 } from "../assets/images/doctors/doctor-09.jpg";
export { default as doctor_10 } from "../assets/images/doctors/doctor-10.jpg";
export { default as doctor_11 } from "../assets/images/doctors/doctor-11.jpg";
export { default as doctor_12 } from "../assets/images/doctors/doctor-12.jpg";
export { default as doctor_14 } from "../assets/images/doctors/doctor-14.jpg";
export { default as doctor_15 } from "../assets/images/doctors/doctor-15.jpg";
export { default as doctor_16 } from "../assets/images/doctors/doctor-16.jpg";
export { default as doctor_17 } from "../assets/images/doctors/doctor-17.jpg";
export { default as doctor_18 } from "../assets/images/doctors/doctor-18.jpg";

export { default as eye_doctor_01 } from "../assets/images/doctors/eye-doctor-01.jpg";
export { default as eye_doctor_02 } from "../assets/images/doctors/eye-doctor-02.jpg";
export { default as eye_doctor_03 } from "../assets/images/doctors/eye-doctor-03.jpg";
export { default as eye_doctor_04 } from "../assets/images/doctors/eye-doctor-04.jpg";
export { default as eye_doctor_05 } from "../assets/images/doctors/eye-doctor-05.jpg";

export { default as store_01 } from "../assets/images/stores/store-01.jpg";
export { default as store_02 } from "../assets/images/stores/store-02.jpg";
export { default as store_03 } from "../assets/images/stores/store-03.jpg";
export { default as store_04 } from "../assets/images/stores/store-04.jpg";
export { default as store_05 } from "../assets/images/stores/store-05.jpg";
export { default as store_06 } from "../assets/images/stores/store-06.jpg";
export { default as store_07 } from "../assets/images/stores/store-07.jpg";
export { default as store_08 } from "../assets/images/stores/store-08.jpg";
export { default as store_09 } from "../assets/images/stores/store-09.jpg";
export { default as store_10 } from "../assets/images/stores/store-10.jpg";
export { default as store_11 } from "../assets/images/stores/store-11.jpg";
export { default as store_12 } from "../assets/images/stores/store-12.jpg";
export { default as store_13 } from "../assets/images/stores/store-13.jpg";
export { default as store_14 } from "../assets/images/stores/store-14.jpg";
export { default as store_15 } from "../assets/images/stores/store-15.jpg";
export { default as store_16 } from "../assets/images/stores/store-16.jpg";

export { default as facility_01 } from "../assets/images/features/facility-01.jpg";
export { default as facility_02 } from "../assets/images/features/facility-02.jpg";
export { default as facility_03 } from "../assets/images/features/facility-03.jpg";
export { default as facility_04 } from "../assets/images/features/facility-04.jpg";
export { default as facility_05 } from "../assets/images/features/facility-05.jpg";
export { default as facility_06 } from "../assets/images/features/facility-06.jpg";
export { default as facility_07 } from "../assets/images/features/facility-07.jpg";

export { default as eye_blog_01 } from "../assets/images/blog/eye-blog-01.jpg";
export { default as eye_blog_02 } from "../assets/images/blog/eye-blog-02.jpg";
export { default as eye_blog_03 } from "../assets/images/blog/eye-blog-03.jpg";
export { default as eye_blog_04 } from "../assets/images/blog/eye-blog-04.jpg";

export { default as doctor_thumb_01 } from "../assets/images/doctors/doctor-thumb-01.jpg";
export { default as doctor_thumb_02 } from "../assets/images/doctors/doctor-thumb-02.jpg";
export { default as doctor_thumb_03 } from "../assets/images/doctors/doctor-thumb-03.jpg";
export { default as doctor_thumb_04 } from "../assets/images/doctors/doctor-thumb-04.jpg";
export { default as doctor_thumb_05 } from "../assets/images/doctors/doctor-thumb-05.jpg";

{
  /*client Home-7 veterinary-home homebanner*/
}
export { default as home_12_banner_bg } from "../assets/images/bg/home-12-banner-bg-1.png";
export { default as home_12_banner_bg2 } from "../assets/images/bg/home-12-banner-bg-2.png";
export { default as home_12_banner_1 } from "../assets/images/bg/home-12-banner-1.png";
export { default as home_12_banner_2 } from "../assets/images/bg/home-12-banner-2.png";
export { default as dot_1 } from "../assets/images/bg/dot-1.png";
export { default as dot_2 } from "../assets/images/bg/dot-2.png";
export { default as ring_1 } from "../assets/images/bg/ring-1.png";
export { default as ring_2 } from "../assets/images/bg/ring-2.png";
{
  /*client Home-7 veterinary-home servicesection*/
}
export { default as big_paw } from "../assets/images/bg/big-paw.png";
export { default as small_paw } from "../assets/images/bg/small-paw.png";
export { default as injection } from "../assets/images/icons/injection.svg";
export { default as bottel } from "../assets/images/icons/bottel.svg";
export { default as bath_tub } from "../assets/images/icons/bath-tub.svg";
export { default as pet_doctor } from "../assets/images/icons/pet-doctor.svg";
{
  /*client Home-7 veterinary-home ourdoctors*/
}
export { default as V_doctor_01 } from "../assets/images/doctors/veterinary-doctor-01.jpg";
export { default as Veterinary_doctor_02 } from "../assets/images/doctors/veterinary-doctor-02.jpg";
export { default as Veterinary_doctor_03 } from "../assets/images/doctors/veterinary-doctor-03.jpg";
export { default as Veterinary_doctor_04 } from "../assets/images/doctors/veterinary-doctor-04.jpg";
{
  /*client Home-7 veterinary-home Feedback*/
}
export { default as two_paw } from "../assets/images/bg/two-paw.png";
export { default as home_12_testimonial } from "../assets/images/bg/home-12-testimonial.png";
export { default as Client_10 } from "../assets/images/clients/client-10.jpg";
export { default as Client_9 } from "../assets/images/clients/client-09.jpg";
{
  /*client Home-7 veterinary-home pricing*/
}
export { default as home_12_pricing_bg_4 } from "../assets/images/bg/home-12-pricing-bg-4.png";
export { default as home_12_pricing_bg_5 } from "../assets/images/bg/home-12-pricing-bg-5.png";
export { default as home_12_pricing_bg_3 } from "../assets/images/bg/home-12-pricing-bg-3.png";
{
  /*client Home-7 veterinary-home blogsection*/
}
export { default as Veterinary_blog_01 } from "../assets/images/blog/veterinary-blog-01.jpg";

export { default as Veterinary_blog_02 } from "../assets/images/blog/veterinary-blog-02.jpg";
export { default as doctor_07 } from "../assets/images/doctors/doctor-07.jpg";
export { default as Veterinary_blog_03 } from "../assets/images/blog/veterinary-blog-03.jpg";
export { default as doctor_06 } from "../assets/images/doctors/doctor-06.jpg";
{
  /*client Home-7 veterinary-home chooseus*/
}
export { default as home_12_why_us } from "../assets/images/bg/home-12-why-us.png";

{
  /*client Home-10 ENT-hospitalhome Homebanner*/
}
export { default as banner_vd } from "../assets/images/banner-fifteen-vd.jpg";
export { default as fifteen_play } from "../assets/images/icons/fifteen-play.svg";
export { default as customer_service_2 } from "../assets/images/icons/customer-service-2.svg";
export { default as customer_service_1 } from "../assets/images/icons/customer-service-1.svg";
export { default as banner_ryt } from "../assets/images/banner-fifteen-ryt.png";
export { default as ban_fift_icon1 } from "../assets/images/ban-fift-icon1.png";
export { default as ban_fift_icon2 } from "../assets/images/ban-fift-icon2.png";
export { default as ban_fift_icon3 } from "../assets/images/ban-fift-icon3.png";
export { default as ban_fift_icon4 } from "../assets/images/ban-fift-icon4.png";
export { default as ban_fift_icon5 } from "../assets/images/ban-fift-icon5.png";
export { default as ban_fift_icon6 } from "../assets/images/ban-fift-icon6.png";
export { default as ban_fift_icon7 } from "../assets/images/ban-fift-icon7.png";
export { default as ban_fift_icon8 } from "../assets/images/ban-fift-icon8.png";
{
  /*client Home-10 ENT-hospitalhome Servicesection*/
}
export { default as serive_bg_icon } from "../assets/images/serive-bg-icon.png";
export { default as service_service_3 } from "../assets/images/service/service-3.jpg";
export { default as service_service_2 } from "../assets/images/service/service-2.jpg";
export { default as service_service_1 } from "../assets/images/service/service-1.jpg";
{
  /*client Home-10 ENT-hospitalhome Patientssection*/
}
export { default as fifteen_bg_icon3 } from "../assets/images/fifteen-bg-icon-3.png";
export { default as patients_img_fifteen } from "../assets/images/patients-img-fifteen.png";
export { default as we_are_icon1 } from "../assets/images/icons/we-are-icon1.svg";
export { default as we_are_icon2 } from "../assets/images/icons/we-are-icon2.svg";
export { default as we_are_icon3 } from "../assets/images/icons/we-are-icon3.svg";
{
  /*client Home-10 ENT-hospitalhome Doctorsection*/
}
export { default as fifteen_bg_icon2 } from "../assets/images/fifteen-bg-icon-2.png";
export { default as fifteen_bg_icon1 } from "../assets/images/fifteen-bg-icon-1.png";
{
  /*client Home-10 ENT-hospitalhome Pharmacysection*/
}
export { default as pharmacy_1 } from "../assets/images/pharmacy-1.png";
export { default as pharmacy_2 } from "../assets/images/pharmacy-2.png";
export { default as pharmacy_3 } from "../assets/images/pharmacy-3.png";
{
  /*client Home-10 ENT-hospitalhome Feedback*/
}
export { default as feedback_fifteen } from "../assets/images/feedback-fifteen.png";
{
  /*client Home-10 ENT-hospitalhome contact*/
}
// export { default as experience_4 } from "../assets/images/icons/experience-4.png";
export { default as experience_1 } from "../assets/images/icons/experience-1.svg";
export { default as experience_2 } from "../assets/images/icons/experience-2.svg";
export { default as experience_3 } from "../assets/images/icons/experience-3.svg";
// export { default as experience_5 } from "../assets/images/icons/experience-5.png";
// export { default as experience_6 } from "../assets/images/icons/experience-6.png";
export { default as appointment_ryt_1 } from "../assets/images/appointment-ryt-1.png";
{
  /*client Home-10 ENT-hospitalhome Patientaboutus*/
}
export { default as aboutus_fift_1 } from "../assets/images/aboutus-fift-1.jpg";
export { default as aboutus_icon1 } from "../assets/images/icons/aboutus-icon1.svg";
// footer
export { default as footer_fift_payment } from "../assets/images/footer-fift-payment.png";
export { default as globe } from "../assets/images/icons/globe.svg";
export { default as devicemessage } from "../assets/images/icons/device-message.svg";
export { default as smartphone } from "../assets/images/icons/smart-phone.svg";
export { default as todayicon } from "../assets/images/icons/today-icon.svg";

export { default as googleimg } from "../assets/images/google-img.svg";
export { default as appimg } from "../assets/images/app-img.svg";
export { default as flag01 } from "../assets/images/flags/flag-01.png";
export { default as flag02 } from "../assets/images/flags/flag-02.png";
export { default as flag03 } from "../assets/images/flags/flag-03.png";
export { default as flag04 } from "../assets/images/flags/flag-04.png";
export { default as flag05 } from "../assets/images/flags/flag-05.png";
export { default as signupUsers } from "../assets/images/icons/signup-users.svg";
export { default as signupUnlock } from "../assets/images/icons/signup-unlock.svg";
export { default as doctorIcon } from "../assets/images/icons/doctor-icon.svg";
export { default as patientIcon } from "../assets/images/icons/patient-icon.svg";
export { default as doctor_banner } from "../assets/images/doctor-banner1.png";
export { default as footer3_logo } from "../assets/images/footer-logo.png";
export { default as product4 } from "../assets/images/product4.png";
export { default as cermax_logo } from "../assets/images/Cermax_logo.png";
export { default as checkBox } from "../assets/images/icons/checkBox.svg";
