import React, { useEffect } from "react";
import { work_01, work_02, work_03, work_04, work_img } from "../../imagepath";
import AOS from "aos";
import "aos/dist/aos.css";

function Worksection() {
  //Aos
  useEffect(() => {
    AOS.init({ duration: 1200, once: true });
  }, []);
  return (
    <>
      <section className="work-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 aos" data-aos="fade-up">
              <div className="section-header-one section-header-slider">
                <h2 className="section-title">Our Services</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-lg-4 col-md-12 work-img-info aos"
              data-aos="fade-up"
            >
              <div className="work-img">
                <img src={work_img} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-lg-8 col-md-12 work-details">
              <div className="section-header-one aos" data-aos="fade-up">
                <h3 className="section-title">
                  4 easy steps to get your solution
                </h3>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6 aos" data-aos="fade-up">
                  <div className="work-info">
                    <div className="work-icon">
                      <span>
                        <img src={work_01} alt="" />
                      </span>
                    </div>
                    <div className="work-content">
                      <h5>Preventive Care</h5>
                      <p>
                        Empower people to achieve health goal by monitoring on
                        daily basis
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 aos" data-aos="fade-up">
                  <div className="work-info">
                    <div className="work-icon">
                      <span>
                        <img src={work_02} alt="" />
                      </span>
                    </div>
                    <div className="work-content">
                      <h5>Primary Care</h5>
                      <p>
                        Provides all subscribers its empanelled doctors,
                        pharmacies and dignostics.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 aos" data-aos="fade-up">
                  <div className="work-info">
                    <div className="work-icon">
                      <span>
                        <img src={work_03} alt="" />
                      </span>
                    </div>
                    <div className="work-content">
                      <h5>Speciality Care Plan</h5>
                      <p>
                        To manage your existing conditions in most efficient and
                        affordable way.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 aos" data-aos="fade-up">
                  <div className="work-info">
                    <div className="work-icon">
                      <span>
                        <img src={work_04} alt="" />
                      </span>
                    </div>
                    <div className="work-content">
                      <h5>Pharmacy& Diagnostics</h5>
                      <p>
                        It is dedicated to provide medicine & diagnostic tests
                        accessible at any time.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Worksection;
