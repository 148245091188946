export { default as Product} from '../../assets/images/products/product.jpg';
export { default as Product1} from '../../assets/images/products/product1.jpg';
export { default as Product2} from '../../assets/images/products/product2.jpg';
export { default as Product3} from '../../assets/images/products/product3.jpg';
export { default as Product4} from '../../assets/images/products/product4.jpg';
export { default as Product5} from '../../assets/images/products/product5.jpg';
export { default as Product6} from '../../assets/images/products/product6.jpg';
export { default as Product7} from '../../assets/images/products/product7.jpg';
export { default as Product8} from '../../assets/images/products/product8.jpg';
export { default as Product10} from '../../assets/images/products/product10.jpg';
export { default as Product11} from '../../assets/images/products/product11.jpg';
export { default as Product12} from '../../assets/images/products/product12.jpg';
export { default as Product13} from '../../assets/images/products/product13.jpg';
export { default as Product14} from '../../assets/images/products/product14.jpg';
export { default as Product15} from '../../assets/images/products/product15.jpg';
export { default as Medicalimg1} from '../../assets/images/medical-img1.jpg';
export { default as Medicalimg2} from '../../assets/images/medical-img2.jpg';
export { default as Medicalimg3} from '../../assets/images/medical-img3.jpg';
export { default as feature01} from '../../assets/images/feature-01.jpg';
export { default as feature02} from '../../assets/images/feature-02.jpg';
export { default as feature03} from '../../assets/images/feature-03.jpg';
export { default as feature04} from '../../assets/images/feature-04.jpg';
export { default as patient} from '../../assets/images/patient.jpg';
export { default as patient1} from '../../assets/images/patient1.jpg';
export { default as patient2} from '../../assets/images/patient2.jpg';

