/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import config from "config";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
// import Header from "./client/components/header.jsx";
// import Footer from "./client/components/footer.jsx";
// import TopHeader from "./client/components/topheader.jsx";
import LoginContainer from "./client/components/login/login.jsx";
import Register from "./client/components/register/register.jsx";
import ForgotPassword from "./client/components/forgot-password";
import Terms from "./client/components/pages/terms";
import Policy from "./client/components/pages/policy";
import Patientregisterstepone from "./client/components/register/patientregisterstepone";
import Patientregistersteptwo from "./client/components/register/patientregistersteptwo";
import Patientregisterstepthree from "./client/components/register/patientregisterstepthree";
import Patientregisterstepfour from "./client/components/register/patientregisterstepfour";
import Patientregisterstepfive from "./client/components/register/patientregisterstepfive";
import Generalhome from "./client/components/home/general/generalhome.jsx";
import ForgotPassword2 from "./client/components/pages/authentication/forgot-password2.jsx";
import QrScanned from "./client/components/pages/qr/index.js";
import UploadRx from "./client/components/pages/qr/UploadRx.js";
import PharmacySearch from "./client/components/Pharmacy/pharmacysearch.jsx";
import BlankPage from "./client/components/pages/starter page/index.jsx";
import Dashboard from "./client/components/patients/dashboard";
import Bills from "./client/components/patients/bills";
import MedicalDetails from "./client/components/patients/medicaldetails";
import ProtectedRoute from "./ProtectedRoute.js";
import { getPatDetails } from "./apis/ProfileApis.js";
import { useAppData } from "./hooks/AppDataContext.jsx";
{
  /*
import Home from "./client/components/home/index";
import Home9 from "./client/components/home/home9";
import Home2 from "./client/components/home/home2";
import Home3 from "./client/components/home/home3";
import Home11 from "./client/components/home/home11";
import Home12 from "./client/components/home/home12";
import Home13 from "./client/components/home/home13";
import Home14 from "./client/components/home/home14";
import HomeSlider1 from "./client/components/home/homeslider1";
import HomeSlider2 from "./client/components/home/homeslider2";
import Home10 from "./client/components/home/home10";
import Home7 from "./client/components/home/home7";
import VideoCall from "./client/components/pages/videocall";
import VoiceCall from "./client/components/pages/voicecall";
import SearchDoctor from "./client/components/pages/searchdoctor/search-doctor1";
import Calendar from "./client/components/pages/calender";
import Invoice from "./client/components/pages/invoices/invoices";
import InvoiceView from "./client/components/pages/invoices/view";
import DoctorGrid from "./client/components/patients/doctorgrid";
import DoctorList from "./client/components/patients/doctorlist";
import DoctorProfile from "./client/components/patients/doctorprofile";
import DoctorChat from "./client/components/doctors/chat";
import PatientChat from "./client/components/patients/chat";
import Booking from "./client/components/patients/booking/booking1";
import Booking2 from "./client/components/patients/booking/booking2";
import Checkout from "./client/components/patients/checkout";
import BookingSuccess from "./client/components/patients/booking-success";

import PatientDependent from "./client/components/patients/dependent";
import PatientAccounts from "./client/components/patients/accounts";
import Orders from "./client/components/patients/orders";
import MedicalRecords from "./client/components/patients/medicalrecords";

import Favourties from "./client/components/patients/dashboard/favourties";
import Profile from "./client/components/patients/dashboard/profile";
import Password from "./client/components/patients/dashboard/password";
import AddPescription from "./client/components/doctors/addpescription";
import AddBilling from "./client/components/doctors/addbilling";
import ProfileSetting from "./client/components/doctors/profilesetting";
import Review from "./client/components/doctors/reviews";
import DoctorRegister from "./client/components/doctors/register";
import Registerstepone from "./client/components/doctors/register/registerstepone";
import Registersteptwo from "./client/components/doctors/register/registersteptwo";
import Registerstepthree from "./client/components/doctors/register/registerstepthree";
import Aboutus from "./client/components/pages/aboutus/aboutus";
import Contactus from "./client/components/pages/contactus/contactus";
import AppUniversal from "./admin/app-universal";
import PharmacyadminApp from "./pharmacyadmin/app-universal";
import EditPrescription from "./client/components/doctors/patientprofile/edit-prescription";
import EditBilling from "./client/components/doctors/editbilling/index";
import MapList from "./client/components/patients/map-list/index";
import OnboardingEmail from "./client/components/pages/doctoronboarding/onboardingemail";
import OnboardingPersonalize from "./client/components/pages/doctoronboarding/onboardingpersonalize";
import OnboardingIdentity from "./client/components/pages/doctoronboarding/onboardingidentity";
import OnboardingPayments from "./client/components/pages/doctoronboarding/onboardingpayments";
import OnboardingPreferences from "./client/components/pages/doctoronboarding/onboardingpreferences";
import Onboardingverification from "./client/components/pages/doctoronboarding/onboardingverification";
import PatientOnboardingEmail from "./client/components/pages/patientonboarding/Email";
import PatientOnboardingPersonalize from "./client/components/pages/patientonboarding/Personalize";
import PatientOnboardingDetails from "./client/components/pages/patientonboarding/Details";
import PatientFamilyDetails from "./client/components/pages/patientonboarding/FamilyDetails";
import DependantDetails from "./client/components/pages/patientonboarding/DependantDetails";
import OtherDetails from "./client/components/pages/patientonboarding/OtherDetails";
import OnboardingEmailOtp from "./client/components/pages/doctoronboarding/onboardingemail-otp";
import Onboardingphone from "./client/components/pages/doctoronboarding/onboardingphone";
import Onboardingphoneotp from "./client/components/pages/doctoronboarding/onboardingphoneotp";
import Onboardingpassword from "./client/components/pages/doctoronboarding/onboardingpassword";
import PatientEmailOtp from "./client/components/pages/patientonboarding/PatientEmailOtp";
import PatientPhone from "./client/components/pages/patientonboarding/Patientphone";
import patientphoneotp from "./client/components/pages/patientonboarding/patientphoneotp";
import patientpassword from "./client/components/pages/patientonboarding/patientpassword";
import PhoneOtp from "./client/components/pages/patientonboarding/phoneotp";
import Producthealthcare from "./client/components/pages/producthealthcare/index";
import Comingsoon from "./client/components/pages/coming soon/index.jsx";
import Maintenance from "./client/components/pages/maintanence/index.jsx";
import PricingPlan from "./client/components/pages/pricing plan/index.jsx";
import Error404 from "./client/components/pages/error/Error404.jsx";
import Error500 from "./client/components/pages/error/Error500.jsx";
import LoginEmail from "./client/components/pages/authentication/login-email.jsx";
import LoginPhone from "./client/components/pages/authentication/login-phone.jsx";
import LoginEmailOtp from "./client/components/pages/authentication/login-email-otp.jsx";
import LoginPhoneOtp from "./client/components/pages/authentication/login-phone-otp.jsx";
import PatientSignup from "./client/components/pages/authentication/patient-signup.jsx";
import Signup from "./client/components/pages/authentication/signup.jsx";
import SuccessSignup from "./client/components/pages/authentication/success-signup.jsx";
import DoctorSignup from "./client/components/pages/authentication/doctor-signup.jsx";
import Home4 from "./client/components/home/home4.jsx";
import Faq from "./client/components/pages/faq/index.jsx";
import EmailOtp from "./client/components/pages/authentication/email-otp.jsx";
import MobileOtp from "./client/components/pages/authentication/phone-otp.jsx";
import Cardiohome from "./client/components/home/cardiology/cardiohome.jsx";
import Paediatrichome from "./client/components/home/paediatric/paediatrichome.jsx";
import Home6 from "./client/components/home/home6.jsx";
import CosmeticsHome from "./client/components/home/home11";
import SearchDoctor2 from "./client/components/pages/searchdoctor/search-doctor2.jsx";
import Consultation from "./client/components/home/consultation.jsx";
import Payment from "./client/components/home/payment.jsx";
import Bookingsuccess from "./client/components/home/bookingsuccess.jsx";
import Patientdetails from "./client/components/home/patientdetails.jsx";
import Loginemail from "./client/components/home/loginemail.jsx";

*/
}

const AppContainer = function (props) {
  // const config = "//";
  const { updateAppData } = useAppData();
  if (props) {
    useEffect(() => {
      const storedUser = JSON.parse(localStorage.getItem("user"));

      const handleMouseMove = (event) => {
        const cursorInner = document.querySelector(".cursor-inner");
        const cursorOuter = document.querySelector(".cursor-outer");

        if (cursorOuter) {
          cursorOuter.style.transform = `translate(${event.clientX}px, ${event.clientY}px)`;
        }

        if (cursorInner) {
          cursorInner.style.transform = `translate(${event.clientX}px, ${event.clientY}px)`;
        }
      };

      const handleMouseEnter = () => {
        const cursorInner = document.querySelector(".cursor-inner");
        const cursorOuter = document.querySelector(".cursor-outer");

        if (cursorInner) {
          cursorInner.classList.add("s");
        }

        if (cursorOuter) {
          cursorOuter.classList.add("s");
        }
      };

      const handleMouseLeave = (event) => {
        const cursorInner = document.querySelector(".cursor-inner");
        const cursorOuter = document.querySelector(".cursor-outer");

        if (
          event.target.tagName !== "A" ||
          !event.target.closest(".cursor-pointer")
        ) {
          if (cursorInner) {
            cursorInner.classList.remove("cursor-hover");
          }

          if (cursorOuter) {
            cursorOuter.classList.remove("cursor-hover");
          }
        }
      };

      document.body.addEventListener("mousemove", handleMouseMove);
      document.body.addEventListener("mouseenter", handleMouseEnter);
      document.body.addEventListener("mouseleave", handleMouseLeave);

      const cursorInner = document.querySelector(".cursor-inner");
      const cursorOuter = document.querySelector(".cursor-outer");

      if (cursorInner) {
        cursorInner.style.visibility = "visible";
      }

      if (cursorOuter) {
        cursorOuter.style.visibility = "visible";
      }

      const getPat = async () => {
        const data = {
          patient_id: storedUser?.id,
        };
        const req = await getPatDetails(data);
        if (req?.id) {
          localStorage.setItem("user", JSON.stringify(req));

          updateAppData({ user: req, isLoggedIn: true });
        }
      };

      return () => {
        document.body.removeEventListener("mousemove", handleMouseMove);
        document.body.removeEventListener("mouseenter", handleMouseEnter);
        document.body.removeEventListener("mouseleave", handleMouseLeave);
        getPat();
      };
    }, []);

    const shouldRedirect = !window.location.pathname.includes("/qr/:pin/:d_id");

    return (
      <Router basename={`${config.publicPath}`}>
        <div>
          <Switch>
            <Route path="/login" exact component={LoginContainer} />
            <Route path="/register" exact component={Register} />
            {shouldRedirect ? <Redirect exact from="/" to="/index" /> : null}
            <Route
              path="/pages/forgot-password"
              exact
              component={ForgotPassword}
            />
            <Route
              path="/pages/forgot-password2"
              exact
              component={ForgotPassword2}
            />

            <Route path="/pages/blank" exact component={BlankPage} />

            <Route path="/qr/:pin/:d_id" component={QrScanned} />
            {/* <Route path="/qr" component={QrScanned} />  for getting ?pin=123456&&d_id=212*/}
            <Route path="/uploadRx" exact component={UploadRx} />
            <Route path="/pharmacy-list" exact component={PharmacySearch} />
            <Route path="/index" exact component={Generalhome} />
            <Route path="/terms" exact component={Terms} />
            <Route path="/privacy-policy" exact component={Policy} />
            <Route
              path="/patient/patientregisterstep-1"
              exact
              component={Patientregisterstepone}
            />
            <Route
              path="/patient/patientregisterstep-2"
              exact
              component={Patientregistersteptwo}
            />
            <Route
              path="/patient/patientregisterstep-3"
              exact
              component={Patientregisterstepthree}
            />
            <Route
              path="/patient/patientregisterstep-4"
              exact
              component={Patientregisterstepfour}
            />
            <Route
              path="/patient/patientregisterstep-5"
              exac
              component={Patientregisterstepfive}
            />
            <ProtectedRoute
              path="/patient/dashboard"
              exact
              component={Dashboard}
            />
            <ProtectedRoute
              path="/patient/medicaldetails"
              exact
              component={MedicalDetails}
            />
            <ProtectedRoute path="/patient/bills" exact component={Bills} />

            {/* <>

            <ProtectedRoute
              path="/patient/doctor-grid"
              exact
              component={DoctorGrid}
            />
            <ProtectedRoute
              path="/patient/doctor-list"
              exact
              component={DoctorList}
            />
            <ProtectedRoute
              path="/pages/video-call"
              exact
              component={VideoCall}
            />
            <ProtectedRoute
              path="/pages/voice-call"
              exact
              component={VoiceCall}
            />
            <ProtectedRoute
              path="/doctor/chat-doctor"
              exact
              component={DoctorChat}
            />

            <ProtectedRoute
              path="/pages/login-email"
              exact
              component={LoginEmail}
            />
            <ProtectedRoute
              path="/pages/login-phone"
              exact
              component={LoginPhone}
            />
            <ProtectedRoute
              path="/pages/email-otp"
              exact
              component={LoginEmailOtp}
            />
            <ProtectedRoute
              path="/pages/phone-otp"
              exact
              component={LoginPhoneOtp}
            />
            <ProtectedRoute path="/pages/eotp" exact component={EmailOtp} />
            <ProtectedRoute path="/pages/motp" exact component={MobileOtp} />

            <ProtectedRoute
              path="/pages/patient-signup"
              exact
              component={PatientSignup}
            />
            <ProtectedRoute
              path="/pages/doctor-signup"
              exact
              component={DoctorSignup}
            />
            <ProtectedRoute
              path="/success-signup"
              exact
              component={SuccessSignup}
            />
            <ProtectedRoute path="/signup" exact component={Signup} />

           
            <ProtectedRoute path="/home" exact component={Home} />

            <ProtectedRoute path="/homeslider1" exact component={HomeSlider1} />
            <ProtectedRoute path="/home2" exact component={Home2} />

            <ProtectedRoute path="/homeslider2" exact component={HomeSlider2} />
            <ProtectedRoute path="/cardiohome" exact component={Cardiohome} />
            <ProtectedRoute
              path="/paediatrichome"
              exact
              component={Paediatrichome}
            />

            <ProtectedRoute path="/home6" exact component={Home6} />
            <ProtectedRoute path="/home7" exact component={Home7} />
            <ProtectedRoute path="/home4" exact component={Home4} />
            <ProtectedRoute path="/home9" exact component={Home9} />
            <ProtectedRoute path="/index10" exact component={Home10} />
            <ProtectedRoute path="/home11" exact component={Home11} />
            <ProtectedRoute
              path="/cosmeticshome"
              exact
              component={CosmeticsHome}
            />
            <ProtectedRoute path="/home12" exact component={Home12} />
            <ProtectedRoute path="/home13" exact component={Home13} />
            <ProtectedRoute path="/home14" exact component={Home14} />

            

            <ProtectedRoute
              path="/patient/search-doctor1"
              exact
              component={SearchDoctor}
            />
            <ProtectedRoute
              path="/patient/search-doctor2"
              exact
              component={SearchDoctor2}
            />
            <ProtectedRoute path="/pages/calendar" exact component={Calendar} />
            <ProtectedRoute path="/pages/invoice" exact component={Invoice} />
            <ProtectedRoute path="/doctor/invoice" exact component={Invoice} />
            <ProtectedRoute
              path="/pages/invoice-view"
              exact
              component={InvoiceView}
            />
            <ProtectedRoute path="/pages/aboutus" exact component={Aboutus} />
            <ProtectedRoute
              path="/pages/contactus"
              exact
              component={Contactus}
            />
            <ProtectedRoute
              path="/pages/comingsoon"
              exact
              component={Comingsoon}
            />
            <ProtectedRoute
              path="/pages/maintenance"
              exact
              component={Maintenance}
            />
            <ProtectedRoute
              path="/pages/pricing-plan"
              exact
              component={PricingPlan}
            />
            <ProtectedRoute
              path="/pages/error-404"
              exact
              component={Error404}
            />
            <ProtectedRoute
              path="/pages/error-500"
              exact
              component={Error500}
            />
            <ProtectedRoute path="/pages/faq" exact component={Faq} />
            <ProtectedRoute
              path="/patient/patientregisterstep-1"
              exact
              component={Patientregisterstepone}
            />
            <ProtectedRoute
              path="/patient/patientregisterstep-2"
              exact
              component={Patientregistersteptwo}
            />
            <ProtectedRoute
              path="/patient/patientregisterstep-3"
              exact
              component={Patientregisterstepthree}
            />
            <ProtectedRoute
              path="/patient/patientregisterstep-4"
              exact
              component={Patientregisterstepfour}
            />
            <ProtectedRoute
              path="/patient/patientregisterstep-5"
              exac
              component={Patientregisterstepfive}
            />
            <ProtectedRoute
              path="/patient/doctor-profile"
              exact
              component={DoctorProfile}
            />

            <ProtectedRoute path="/patient/booking" exact component={Booking} />
            <ProtectedRoute
              path="/patient/booking2"
              exact
              component={Booking2}
            />
            <ProtectedRoute
              path="/patient/patient-chat"
              exact
              component={PatientChat}
            />
            <ProtectedRoute
              path="/patient/checkout"
              exact
              component={Checkout}
            />
            <ProtectedRoute
              path="/patient/booking-success"
              exact
              component={BookingSuccess}
            />
            <ProtectedRoute
              path="/patient/dashboard"
              exact
              component={Dashboard}
            />
            <ProtectedRoute
              path="/patient/dependent"
              exact
              component={PatientDependent}
            />
            <ProtectedRoute
              path="/patient/accounts"
              exact
              component={PatientAccounts}
            />
            <ProtectedRoute path="/patient/orders" exact component={Orders} />
            <ProtectedRoute
              path="/patient/medicalrecords"
              exact
              component={MedicalRecords}
            />
          
            <ProtectedRoute
              path="/patient/favourites"
              exact
              component={Favourties}
            />
            <ProtectedRoute path="/patient/profile" exact component={Profile} />
            <ProtectedRoute
              path="/patient/change-password"
              exact
              component={Password}
            />

            <ProtectedRoute
              path="/add-prescription"
              exact
              component={AddPescription}
            />
            <ProtectedRoute path="/add-billing" exact component={AddBilling} />
            <ProtectedRoute
              path="/doctor/profile-setting"
              exact
              component={ProfileSetting}
            />
            <ProtectedRoute path="/doctor/review" exact component={Review} />
            <ProtectedRoute
              path="/doctor/doctor-register"
              exact
              component={DoctorRegister}
            />
            <ProtectedRoute
              path="/registerstepone"
              exact
              component={Registerstepone}
            />
            <ProtectedRoute
              path="/register-step-2"
              exact
              component={Registersteptwo}
            />
            <ProtectedRoute
              path="/register-step- 3"
              exact
              component={Registerstepthree}
            />

            <ProtectedRoute
              path="/editprescription"
              exact
              component={EditPrescription}
            />
            <ProtectedRoute path="/editbilling" exact component={EditBilling} />
            <ProtectedRoute
              path="/patient/map-list"
              exact
              component={MapList}
            />
            <ProtectedRoute
              path="/pages/onboarding-email"
              exact
              component={OnboardingEmail}
            />
            <ProtectedRoute
              path="/pages/onboarding-identity"
              exact
              component={OnboardingIdentity}
            />
            <ProtectedRoute
              path="/pages/onboarding-payments"
              exact
              component={OnboardingPayments}
            />
            <ProtectedRoute
              path="/pages/onboarding-personalize"
              exact
              component={OnboardingPersonalize}
            />
            <ProtectedRoute
              path="/pages/onboarding-preferences"
              exact
              component={OnboardingPreferences}
            />
            <ProtectedRoute
              path="/pages/onboarding-verification"
              exact
              component={Onboardingverification}
            />
            <ProtectedRoute
              path="/pages/patient-email"
              exact
              component={PatientOnboardingEmail}
            />
            <ProtectedRoute
              path="/pages/patient-personalize"
              exact
              component={PatientOnboardingPersonalize}
            />
            <ProtectedRoute
              path="/pages/patient-details"
              exact
              component={PatientOnboardingDetails}
            />
            <ProtectedRoute
              path="/pages/patient-family-details"
              exact
              component={PatientFamilyDetails}
            />
            <ProtectedRoute
              path="/pages/patient-dependant-details"
              exact
              component={DependantDetails}
            />
            <ProtectedRoute
              path="/pages/patient-other-details"
              exact
              component={OtherDetails}
            />
            <ProtectedRoute
              path="/pages/onboarding-email-otp"
              exact
              component={OnboardingEmailOtp}
            />
            <ProtectedRoute
              path="/pages/onboarding-phone"
              exact
              component={Onboardingphone}
            />
            <ProtectedRoute
              path="/pages/onboarding-phone-otp"
              exact
              component={Onboardingphoneotp}
            />
            <ProtectedRoute
              path="/pages/onboarding-password"
              exact
              component={Onboardingpassword}
            />
            <ProtectedRoute
              path="/pages/patient-email-otp"
              exact
              component={PatientEmailOtp}
            />
            <ProtectedRoute
              path="/pages/patient-phone"
              exact
              component={PatientPhone}
            />
            <ProtectedRoute
              path="/pages/patient-phone-otp"
              exact
              component={patientphoneotp}
            />
            <ProtectedRoute
              path="/pages/patient-password"
              exact
              component={patientpassword}
            />
            <ProtectedRoute
              path="/pages/product-healthcare"
              exact
              component={Producthealthcare}
            />
            <ProtectedRoute
              path="/pages/patient-phone-otp"
              exact
              component={PhoneOtp}
            />

            <ProtectedRoute
              path="/consultation"
              exact
              component={Consultation}
            />
            <ProtectedRoute path="/payment" exact component={Payment} />
            <ProtectedRoute
              path="/bookingsuccess"
              exact
              component={Bookingsuccess}
            />
            <ProtectedRoute
              path="/patientdetails"
              exact
              component={Patientdetails}
            />
            <ProtectedRoute path="/loginemail" exact component={Loginemail} />
            </> */}
          </Switch>
        </div>
      </Router>
    );
  }
  return null;
};

export default AppContainer;
