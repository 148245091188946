import React, { useState, createContext, useContext, useEffect } from "react";

const AppDataContext = createContext(null);

export const useAppData = () => {
  const context = useContext(AppDataContext);

  if (!context) {
    throw new Error("useAppData must be used within an AppDataProvider");
  }

  return context;
};

export const AppDataProvider = ({ children }) => {
  useEffect(() => {
    async function fetchData() {
      const storedUser = await JSON.parse(localStorage.getItem("user"));
      const scannerData = await JSON.parse(localStorage.getItem("scanner"));
      setAppData({ user: storedUser, isLoggedIn: true, scanner: scannerData });
    }
    fetchData();
  }, []);

  const [appData, setAppData] = useState({
    user: null,
    isLoggedIn: false,
    scanner: null,
  });

  // Function to update the global data
  const updateAppData = (newData) => {
    setAppData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  return (
    <AppDataContext.Provider value={{ appData, updateAppData }}>
      {children}
    </AppDataContext.Provider>
  );
};
