import React, { useState, useEffect } from "react";
import Header from "../../header";
import {
  dot_1,
  dot_2,
  product4,
  ring_1,
  ring_2,
  cermax_logo,
} from "../../imagepath";
import { useParams, useHistory, Link } from "react-router-dom";
import { useAppData } from "../../../../hooks/AppDataContext";
import {
  PatientLogin,
  PatientRegister,
  Verify_otp,
} from "../../../../apis/authApis";

const QrScanned = (props) => {
  let history = useHistory();
  const params = useParams();
  // const location = useLocation();
  // const pinCode = new URLSearchParams(location.search).get("pin");
  // const doc_id = new URLSearchParams(location.search).get("d_id");

  const { pin, d_id } = params;
  const { appData, updateAppData } = useAppData();

  const [name, setName] = useState("");
  const [userId, setUserId] = useState("");
  const [surName, setSurName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [alert, setAlert] = useState(false);
  const [errText, setErrText] = useState("");

  const [OtpPop, setOtpPop] = useState("Register");

  const [password, setPassword] = useState("");
  const [timer, setTimer] = useState(90);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [err, setErr] = useState("");
  const Login = async () => {
    const data = {
      mobileno: phone,
      password: password,
      pin: pin,
    };
    //to get your password for login please enter your email id

    if (phone == "") {
      setErr("Email must be specified");
    } else if (password == "") {
      setErr("Password must be specified");
    } else {
      let req = await PatientLogin(data);
      // console.log("28==========>",req);
      if (req?.data?.id) {
        localStorage.setItem("user", JSON.stringify(req.data));
        history.push({
          pathname: "/uploadRx",
          search: `?name=patient`,
        });
        updateAppData({ user: req.data, isLoggedIn: true });
      } else {
        setErr(req.message);
      }
    }
  };
  const otpInputs = Array.from({ length: 4 });

  const maskedPhoneNumber =
    phone?.length == 10 ? "******" + phone?.slice(-4) : null;

  const handleChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    setErrText("");
    // Auto-focus on the next input if the current input is filled
    if (value !== "" && index < otpInputs.length - 1) {
      otpInputs[index + 1].focus();
    }
    // move to previous input if the current input is filled
    if (value === "" && index > 0) {
      otpInputs[index - 1].focus();
    }
  };

  useEffect(() => {
    document.body.classList.add("account-page");
    console.log("1", pin, d_id);
    let pinData = {
      pin: pin,
      d_id: d_id,
    };
    localStorage.setItem("scanner", JSON.stringify(pinData));
    updateAppData({
      scanner: pinData,
    });
    console.log(appData);
    return () => {
      document.body.classList.remove("account-page");
      // clearTimeout(alertTimer);
    };
  }, []);

  let renderedComponent;

  switch (OtpPop) {
    case "Otp":
      renderedComponent = (
        <div className="col-md-12 col-lg-6 login-right">
          <div className="login-header">
            <h3 className="text-center">
              Enter <span>OTP</span>
            </h3>
            <p className="mb-0 text-center">
              OTP sent to your mobile <strong>{maskedPhoneNumber}</strong>
            </p>
          </div>

          <form>
            <div className="otp-box">
              <div className="form-group">
                {otpInputs.map((_, index) => (
                  <input
                    key={index}
                    value={otp[index]}
                    type="text"
                    id={index}
                    maxLength={1}
                    onChange={(e) => handleChange(index, e.target.value)}
                    ref={(input) => (otpInputs[index] = input)}
                    onPaste={handlePaste}
                  />
                ))}
              </div>
            </div>
            {errText?.length > 0 ? (
              <div className="text-danger" role="alert">
                <strong>Warning!</strong> {errText}
              </div>
            ) : null}

            <a
              //  to="/doctor/doctor-dashboard"
              className="btn btn-primary w-100 btn-lg login-btn"
              style={{ marginTop: "20px" }}
              type="submit"
              onClick={() => VerifyOtp()}
            >
              Verify Otp
            </a>

            {/* {timer > 0 ? (
              <p className="my-4 text-center ">
                Wait for <strong>{timer}</strong>
              </p>
            ) : (
              <p
                className="my-4 text-center "
                onClick={() => handleResendOtp()}
              >
                {" "}
                <strong>Resend Otp</strong>
              </p>
            )} */}
          </form>
        </div>
      );

      break;
    case "Register":
      renderedComponent = (
        <div className="col-md-12 col-lg-6 login-right">
          <p className="text-left mb-0">Powered By</p>
          <div className="pricing-header-info">
            <div className="pricing-icon">
              <img
                src={cermax_logo}
                alt="icon"
                style={{ height: "80px", width: "120px" }}
              />
            </div>
            <div className="pricing-title">
              <h1 className="fa-lx">Cermax</h1>
              <p> Pharmacy Network</p>
            </div>
          </div>

          <form>
            <div className="row row-cols-2">
              <div className="form-group">
                <label>Patient Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setErrText("");
                  }}
                />
              </div>
              <div className="form-group">
                <label>Patient Surname</label>
                <input
                  type="text"
                  className="form-control"
                  value={surName}
                  onChange={(e) => {
                    setSurName(e.target.value);
                    setErrText("");
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <label>Patient Phone No.</label>
              <input
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                  setErrText("");
                }}
                type="tel"
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>Patient Email id</label>
              <input
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setErrText("");
                }}
                type="tel"
                className="form-control"
              />
            </div>
            {errText?.length > 0 ? (
              <div className="text-danger" role="alert">
                <strong>Warning!</strong> {errText}
              </div>
            ) : null}

            <a
              //  to="/doctor/doctor-dashboard"
              className="btn btn-primary w-100 btn-lg login-btn"
              style={{ marginTop: "20px" }}
              type="submit"
              onClick={() => RegisterPatient()}
            >
              Get OTP
            </a>
            <div className="text-center dont-have">
              Already have an account?{" "}
              <a onClick={() => setOtpPop("Login")}> Login</a>
            </div>
          </form>
        </div>
      );
      break;
    case "Login":
      renderedComponent = (
        <div className="col-md-12 col-lg-6 login-right">
          <div className="login-header">
            <h3>
              PATIENT <span>LOGIN</span>
            </h3>
          </div>
          <p className="text-left mb-0">Powered By</p>
          <div className="pricing-header-info">
            <div className="pricing-icon">
              <img
                src={cermax_logo}
                alt="icon"
                style={{ height: "80px", width: "120px" }}
              />
            </div>
            <div className="pricing-title">
              <h1 className="fa-lx">Cermax</h1>
              <p> Pharmacy Network</p>
            </div>
          </div>
          <form>
            <div className="form-group">
              <label className="focus-label">
                Phone<span className="text-danger">*</span>
              </label>
              <input
                type="tel"
                className="form-control"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label className="focus-label">
                Password<span className="text-danger">*</span>
              </label>
              <input
                type="password"
                className="form-control"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            {err?.length > 0 ? (
              <div className="text-danger" role="alert">
                <strong>Warning!</strong> {err}
              </div>
            ) : null}
            <div className="text-end">
              <Link className="forgot-link" to="/pages/forgot-password">
                Forgot Password ?
              </Link>
            </div>

            <a
              // to="/patient/dashboard"
              className="btn btn-primary w-100 btn-lg login-btn"
              type="submit"
              onClick={() => Login()}
            >
              Login
            </a>
            {/* <div className="login-or">
              <span className="or-line" />
              <span className="span-or">or</span>
            </div> */}

            <div className="text-center dont-have">
              Don’t have an account?{" "}
              <a onClick={() => setOtpPop("Register")}>Register</a>
            </div>
          </form>
        </div>
      );
      break;
    default:
      renderedComponent = null;
  }

  // const handleResendOtp = () => {
  //   // Add logic to resend OTP here
  //   // Reset the timer to 90 seconds
  //   setTimer(90);
  // };

  // const RegisterPatient = () => {
  //   setOtpPop("Otp");
  // };

  // const VerifyOtp = () => {
  //   console.log(otp.join(""), typeof otp.join(""));
  //   history.push({
  //     pathname: "/uploadRx",
  //     search: `?n=${name}&s=${surName}`,
  //   });
  // };

  const RegisterPatient = async () => {
    if (name == "" || surName == "" || phone == "") {
      setErrText(
        `${
          name == "" ? "Name" : surName == "" ? "Surname" : "Phone Number"
        } is required`
      );
    } else if (email == "") {
      setErrText("Enter your email address to receive your login password.");
    } else {
      const data = {
        patient_name: name,
        patient_lname: surName,
        email: email,
        mobileno: phone,
        pincode: pin,
      };
      console.log("check 103", data);

      let req = await PatientRegister(data);
      console.log("======> res for register patients", req?.data);
      if (req?.data?.status == "patient registration  success") {
        setOtpPop("Otp");
        localStorage.setItem("user", JSON.stringify(req?.data));
        updateAppData({
          user: req?.data,
        });
        setUserId(req?.data);
        setErrText("");

        let countdown;
        const startTimer = () => {
          countdown = setInterval(() => {
            setTimer((prevTimer) => (prevTimer === 0 ? 0 : prevTimer - 1));
          }, 1000);
        };
        startTimer();
        return () => clearInterval(countdown);
      } else if (req?.mobileno) {
        setErrText("This Phone number is already used, please login");
      }
    }
  };

  const VerifyOtp = async () => {
    // console.log(otp.join(''))
    if (otp.join("") == "") {
      setErrText("Otp cannot be empty");
    } else {
      const data = {
        id: userId,
        username: `pat${userId}`,
        otp: otp.join(""),
      };
      console.log(data);
      let req = await Verify_otp(data);
      console.log("verify OTP res", req);
      if (
        req?.success ==
          "Verification with username and mobilenumber is done,  Please set and do login with mpin" ||
        req?.success == "Successfully logged in, please set mpin for"
      ) {
        setAlert(true);
        const alertTimer = setTimeout(() => {
          setAlert(false);
        }, 5000);
        setErrText("");
        alertTimer;
        history.push({
          pathname: "/uploadRx",
          search: `?name=${name}&d_id=${surName}`,
        });
      }
      // else if (
      //   req?.response?.data?.messages?.error === "Time Exceeds, Resend OTP" ||
      //   req?.response?.data?.messages?.error === "Wrong OTP entered, Resend otp"
      // ) {
      //   setErrText(req?.response?.data?.messages?.error);
      // } else {
      //   setErrText("Something went wrong please try after some time");
      // }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").trim();
    if (pastedData.length === otp.length) {
      const newOtp = pastedData.split("").slice(0, otp.length);
      setOtp(newOtp);
      // Update input values
      newOtp.forEach((value, index) => {
        if (otpInputs[index].current) {
          otpInputs[index].current.value = value;
        }
      });
    }
  };

  return (
    <div>
      <Header {...props} />

      <div className="content doctors-section">
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {alert ? (
            <div
              className="w-75 alert alert-success alert-dismissible fade show "
              role="alert"
              style={{ marginTop: "7vh" }}
            >
              <strong>Success!</strong> Your Otp is Verified{" "}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true"></span>
              </button>
            </div>
          ) : null}
        </div>
        <div className="container-fluid">
          <div
            className="section-header-three text-center aos"
            data-aos="fade-up"
            style={{ paddingTop: "10vh" }}
          >
            <h2>Cermax Pharmacy Discounted Billing For Members</h2>
            <p className="fs-6">Authenticate your name and mobile number</p>
          </div>

          <div className="row">
            <div className="col-md-8 offset-md-2">
              {/* Login Tab Content */}
              <div className="account-content">
                <div className="row align-items-center justify-content-center">
                  <div className="row col-lg-6">
                    <div
                      className="banner-img banner-img-twelve aos"
                      data-aos="fade-up"
                    >
                      <img src={product4} className="img-fluid" alt="" />
                      <div className="banner-banner-img-twelve-bg">
                        <img src={dot_1} alt="" />
                        <img src={dot_2} alt="" />
                        <img src={ring_1} alt="" />
                        <img src={ring_2} alt="" />
                      </div>
                    </div>

                    {/* <img src={home_12_banner_bg2} className="img-fluid" alt="" /> */}
                  </div>

                  {renderedComponent}
                </div>
              </div>
              {/* /Login Tab Content */}
            </div>
          </div>
        </div>
      </div>

      <footer
        className="footer footer-one"
        // style={{ padding: !showCopyRightBar ? 0 : 50 }}
        style={{
          position: "absolute",
          left: 0,
          bottom: 0,
          right: 0,
        }}
      >
        <div className="footer-bottom">
          <div className="container">
            {/* Copyright */}
            <div className="copyright">
              {/* <div className="navbar-header">
                <Link to="/index" className="navbar-brand logo">
                  <img src={logo} alt="logo" className="img-fluid" />
                </Link>
              </div> */}

              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <div className="copyright-text">
                    <p className="mb-0">
                      {" "}
                      Copyright © 2015{" "}
                      <a href="https://certumventures.in/" target="blank">
                        certumventures.
                      </a>{" "}
                      All Rights Reserved
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6">
                  {/* Copyright Menu */}
                  <div className="copyright-menu">
                    <ul className="policy-menu">
                      <li>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/terms">Terms and Conditions</Link>
                      </li>
                    </ul>
                  </div>
                  {/* /Copyright Menu */}
                </div>
              </div>
            </div>
            {/* /Copyright */}
          </div>
        </div>
      </footer>
    </div>
  );
};

export default QrScanned;
