import React, { useState, useEffect } from "react";
import Header from "../header";
import { GetPharmaList, SendToPharmacy } from "../../../apis/UploadRxApis";
import { logo } from "../imagepath";
import { useAppData } from "../../../hooks/AppDataContext";
import { Link, useLocation } from "react-router-dom";

const PharmacySearch = (props) => {
  const { appData } = useAppData();
  const user = JSON.parse(localStorage.getItem("user"));

  const location = useLocation();
  const pres_id = new URLSearchParams(location.search).get("id");
  const [data, setData] = useState([]);
  const [successModel, setSuccessModel] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const data = {
        lat: await user?.latitude,
        lng: await user?.longitude,
        // lat: 30,
        // lng: 30,
      };

      const req = await GetPharmaList(data);
      console.log(req);
      if (req?.response) {
        const sortedData = cermaxAtTop(req?.response);
        setData(sortedData);
      }
    }
    fetchData();
  }, []);

  const handlePhoneLinkClick = (phone) => {
    window.location.href = `tel:${phone}`;
  };

  const cermaxAtTop = (data) => {
    const lowestIdItem = data.reduce((min, item) =>
      item.id < min.id ? item : min
    );
    // const id213Item = data?.find((item) => item.id === 213);

    const restOfItems = data.filter((item) => item.id !== lowestIdItem.id);
    return [lowestIdItem, ...restOfItems];
  };

  const SendRxToPharam = async (item) => {
    const data = {
      pres_id: pres_id,
      pat_id: appData?.user?.id,
      phar_id: item?.staff_id,
    };
    try {
      const response = await SendToPharmacy(data);

      console.log(response.data);
      setSuccessModel(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="main-wrapper">
      <Header {...props} />

      {/* Page Content */}
      <div className="content" style={{ display: "flex", minHeight: "85vh" }}>
        <div className="container-fluid">
          <div
            className="section-header-three text-center aos"
            data-aos="fade-up"
            style={{ paddingTop: "10vh" }}
          >
            <h2>Send Prescription For Billing</h2>
            <p className="fs-6">
              Select the nearest pharmacy to bill your prescription.
            </p>
          </div>
          <div className="row">
            {successModel ? (
              <div
                className="alert alert-success alert-dismissible fade show"
                role="alert"
                style={{ marginTop: "7vh" }}
              >
                <strong></strong> Prescription submitted successfully.!{" "}
                <Link to="#" className="alert-link">
                  Your prescription has been sent to the selected pharmacy,
                </Link>{" "}
                your medications will be ready for delivery as soon as possible.
                Stay healthy!
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setSuccessModel(false)}
                >
                  <span aria-hidden="true"></span>
                </button>
              </div>
            ) : null}
            <div className="col-md-12 col-lg-8 col-xl-9">
              {/* Doctor Widget */}

              {data?.map((item, index) => (
                <div className="card" key={index}>
                  <div className="card-body">
                    <div className="doctor-widget">
                      <div className="doc-info-left">
                        <div className="doctor-img1">
                          <img
                            src={item.image == "" ? logo : item.image}
                            className="img-fluid"
                            alt="User Image"
                            style={{
                              height: "100px",
                              width: "100px",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                        <div className="doc-info-cont">
                          <h4 className="doc-name mb-2">
                            {item?.pharmacy_name == "Change to your clinic name"
                              ? `${item?.name} ${item?.surname}`
                              : item?.pharmacy_name}
                            {/* {item?.title} */}
                          </h4>
                          <div className="clinic-details">
                            <div className="clini-infos pt-3">
                              <p className="doc-location mb-2">
                                <i className="fas fa-map-marker-alt me-1" />{" "}
                                <a
                                  className="apt-btn"
                                  //  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`}
                                  href={`https://www.google.com/maps/search/?api=1&query=${item?.latitude},${item?.longitude}`}
                                  target="blank"
                                >
                                  {item?.local_address}
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="doc-info-right">
                        <div className="clinic-booking">
                          <a
                            className="view-pro-btn"
                            onClick={() => handlePhoneLinkClick(9972065757)}
                          >
                            Call
                          </a>
                          <a
                            className="apt-btn"
                            onClick={() => SendRxToPharam(item)}
                          >
                            Send Prescription
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              {/* /Doctor Widget */}
              {/* <div className="load-more text-center">
                <Link className="btn btn-primary btn-md" to="#">
                  Load More
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* /Page Content */}
      {/* <Footer
        {...props}
        showLogo={true}
        showCopyRightBar={false}
        showCompanyDetail={true}
        showRoutes={true}
        showCopyrightLogo={true}
      /> */}
      <footer
        className="footer footer-one"
        // style={{ padding: !showCopyRightBar ? 0 : 50 }}
        // style={{
        //   position: "absolute",
        //   left: 0,
        //   bottom: 0,
        //   right: 0,
        // }}
      >
        <div className="footer-bottom">
          <div className="container">
            {/* Copyright */}
            <div className="copyright">
              <div className="navbar-header">
                <Link to="/index" className="navbar-brand logo">
                  <img src={logo} alt="logo" className="img-fluid" />
                </Link>
              </div>

              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <div className="copyright-text">
                    <p className="mb-0">
                      {" "}
                      Copyright © 2015{" "}
                      <a href="https://certumventures.in/" target="blank">
                        certumventures.
                      </a>{" "}
                      All Rights Reserved
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6">
                  {/* Copyright Menu */}
                  <div className="copyright-menu">
                    <ul className="policy-menu">
                      <li>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/terms">Terms and Conditions</Link>
                      </li>
                    </ul>
                  </div>
                  {/* /Copyright Menu */}
                </div>
              </div>
            </div>
            {/* /Copyright */}
          </div>
        </div>
      </footer>
    </div>
  );
};

export default PharmacySearch;
