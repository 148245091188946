import { apiphpClient } from "./configs/axiosConfig";

export const getPatDetails = async (data) => {
  let responseToBeSent;
  await apiphpClient
    .post(`landingpage/patientProfile`, data)
    .then((res) => {
      responseToBeSent = res.data.messages.profile_data.resultlist;
    })
    .catch((err) => {
      console.log("Error in get doc details ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};
