import React, { useEffect, useState } from "react";
import DashboardSidebar from "../dashboard/sidebar/sidebar.jsx";
import { Modal } from "react-bootstrap";
import StickyBox from "react-sticky-box";
import { Link } from "react-router-dom";
import Header from "../../header";
import Footer from "../../footer";
import { getPharmacyBillDetails } from "../../../../apis/ReportsAndBills.js";
import { apiphpClient } from "../../../../apis/configs/axiosConfig.js";
import JsPDF from "jspdf";
import html2canvas from "html2canvas";

const MedicalDetails = (props) => {
  const [show, setShow] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [data, setData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const data = {
        patient_id: user?.id,
      };

      const req = await getPharmacyBillDetails(data);
      console.log(req);
      setData(req);
    }
    fetchData();
  }, []);

  const fetchParticularPharmacyReports = async (report_id) => {
    let bodyFormData = new FormData();
    bodyFormData.append("report_id", report_id);

    let responseToBeSentReport;

    await apiphpClient({
      method: "post",
      url: `/PatientPharmacy/viewPharmReport
            `,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(async function (response) {
        await createPDF(
          "Pharmacy",
          response.data.messages.Patient_pharmacybill,
          response.data.messages.Patient_pharmacybill.detail,
          `PHA${response.data.messages.Patient_pharmacybill.id}`,
          "",
          response.data.messages.Patient_pharmacybill.print_details
        );
      })
      .catch(function (error) {
        console.log("==================error==================");
        console.log(error.message);
        console.log("====================================");
        responseToBeSentReport = { ...error };
      });

    return responseToBeSentReport;
  };
  const htmlStringToPdf = async (htmlString) => {
    let iframe = document.createElement("iframe");
    iframe.style.visibility = "hidden";
    document.body.appendChild(iframe);
    let iframedoc = iframe.contentDocument || iframe.contentWindow.document;
    iframedoc.body.innerHTML = htmlString;

    let canvas = await html2canvas(iframedoc.body, {});

    // Convert the iframe into a PNG image using canvas.
    let imgData = canvas.toDataURL("image/png");

    // Create a PDF document and add the image as a page.
    const doc = new JsPDF({
      format: "a4",
      unit: "mm",
    });
    doc.addImage(imgData, "PNG", 0, 0, 210, 297);

    // Get the file as blob output.
    let pdfBlob = doc.output("blob");

    // Remove the iframe from the document when the file is generated.
    document.body.removeChild(iframe);

    const pdfBlobUrl = URL.createObjectURL(pdfBlob);

    // Open the PDF in a new tab.
    window.open(pdfBlobUrl, "_blank");
  };
  const createPDF = async (
    reportOverviewData,
    testsArray,
    billPrefix,
    printDetails
  ) => {
    //For OPD only

    let clinicLogo = printDetails.image_string + printDetails.print_header;

    const html = `
            <html>

            <head>
                <style>
                    #main {
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        justify-content: center;
                        align-items: center;
                    }
            
                    ;
                </style>
            </head>
            
            <body>
                <div id="main">
                    <div style="display: inline;">
                        <img src=${clinicLogo} width="100%" height="auto"/>
                    </div>
                </div>
            
            
                <div style="margin-top: 2%;">
                    <div style="justify-content: space-between; align-items: center; flex-direction: row; display:flex;">
                        <div
                            style="justify-content: space-between; align-items: flex-start; flex-direction: row; display:flex; width: 50%; margin-right: 2%;">
                            <div style="">
                                <p style="margin: 0; font-weight: bold; font-family: sans-serif;">Bill No : </p>
                            </div>
                            <div style="flex-wrap: wrap; display: flex; width: 40%;">
                                <p style="margin: 0; flex-wrap: wrap; display:flex; text-align: left; font-family: sans-serif;">
                                    ${billPrefix}</p>
                            </div>
                        </div>
                        <div
                            style="justify-content: space-between; align-items: flex-start; flex-direction: row; display:flex; width: 50%; margin-right: 2%;">
                            <div style="">
                                <p style="margin: 0; font-weight: bold; font-family: sans-serif;">Date: </p>
                            </div>
                            <div style="width: auto; flex-wrap: wrap; display: flex; width: 40%;">
                                <p style="margin: 0; flex-wrap: wrap; display:flex; text-align: left; font-family: sans-serif;">
                                    ${reportOverviewData.result.date}</p>
                            </div>
                        </div>
                    </div>
                    <div style="justify-content: space-between; align-items: center; flex-direction: row; display:flex;">
                        <div
                            style="justify-content: space-between; align-items: flex-start; flex-direction: row; display:flex; width: 50%; margin-right: 2%;">
                            <div style="">
                                <p style="margin: 0; font-weight: bold; font-family: sans-serif;">Name: </p>
                            </div>
                            <div style="flex-wrap: wrap; display: flex; width: 40%;">
                                <p style="margin: 0; flex-wrap: wrap; display:flex; text-align: left; font-family: sans-serif;">
                                    ${
                                      reportOverviewData.result.patient_name
                                    } (${
      reportOverviewData.result.patientid
    })</p>
                            </div>
                        </div>
                        <div
                            style="justify-content: space-between; align-items: flex-start; flex-direction: row; display:flex; width: 50%; margin-right: 2%;">
                            <div style="">
                                <p style="margin: 0; font-weight: bold; font-family: sans-serif;">Doctor: </p>
                            </div>
                            <div style="flex-wrap: wrap; display: flex; width: 40%;">
                                <p style="margin: 0; flex-wrap: wrap; display:flex; text-align: left; font-family: sans-serif;">
                                    ${reportOverviewData.result.doctor_name}</p>
                            </div>
                        </div>
                    </div>
                    <div style="justify-content: space-between; align-items: center; flex-direction: row; display:flex;">
                        <div
                            style="justify-content: space-between; align-items: flex-start; flex-direction: row; display:flex; width: 50%; margin-right: 2%;">
                            <div style="">
                                <p style="margin: 0; font-weight: bold; font-family: sans-serif;">Case ID</p>
                            </div>
                            <div style="flex-wrap: wrap; display: flex; width: 40%;">
                                <p style="margin: 0; flex-wrap: wrap; display:flex; text-align: left; font-family: sans-serif;">
                                    ${
                                      reportOverviewData.result
                                        .case_reference_id
                                    }</p>
                            </div>
                        </div>
                        <div
                            style="justify-content: space-between; align-items: flex-start; flex-direction: row; display:flex; width: 50%; margin-right: 2%;">
                            <div style="">
                                <p style="margin: 0; font-weight: bold; font-family: sans-serif;">Phone: </p>
                            </div>
                            <div style="flex-wrap: wrap; display: flex; width: 40%;">
                                <p style="margin: 0; flex-wrap: wrap; display:flex; text-align: left; font-family: sans-serif;">
                                    ${reportOverviewData.result.mobileno}</p>
                            </div>
                        </div>
                    </div>
                    <div style="justify-content: space-between; align-items: center; flex-direction: row; display:flex;">
                        <div
                            style="justify-content: space-between; align-items: flex-start; flex-direction: row; display:flex; width: 50%; margin-right: 2%;">
                            <div style="">
                                <p style="margin: 0; font-weight: bold; font-family: sans-serif;">Prescription:</p>
                            </div>
                            <div style="flex-wrap: wrap; display: flex; width: 40%;">
                                <p style="margin: 0; flex-wrap: wrap; display:flex; text-align: left; font-family: sans-serif;"></p>
                            </div>
                        </div>
                        <div
                            style="justify-content: space-between; align-items: flex-start; flex-direction: row; display:flex; width: 50%; margin-right: 2%;">
                            <div style="">
                                <p style="margin: 0; font-weight: bold; font-family: sans-serif;"></p>
                            </div>
                            <div style="flex-wrap: wrap; display: flex; width: 40%;">
                                <p style="margin: 0; flex-wrap: wrap; display:flex; text-align: left; font-family: sans-serif;"></p>
                            </div>
                        </div>
                    </div>
                </div>
            
                <hr>
            
                <table style="width:100%; margin-top: 2%; font-family: sans-serif;">
                    <tr style="vertical-align: top;">
                        <th style="width:10%; text-align: left;">Medicine Category</th>
                        <th style="width:30%; text-align: center;">Medicine Name</th>
                        <th style="width:10%; text-align: left;">Batch No</th>
                        <th style="width:10%; text-align: left;">Expiry Date</th>
                        <th style="width:10%; text-align: left;">Quantity</th>
                        <th style="width:10%; text-align: left;">Tax</th>
                        <th style="width:10%; text-align: left;">Amount</th>
                    </tr>
            
                    ${testsArray.map(
                      (item) => `
                        <tr style="vertical-align: top;">
                            <td>${item.medicine_category}</td>
                            <td style="text-align: center">${item.medicine_name}</td>
                            <td>${item.batch_no}</td>
                            <td>${item.expiry}</td>
                            <td>${item.quantity}</td>
                            <td>${item.tax}%</td>
                            <td>${item.sale_price}</td>
                        </tr>
                    `
                    )}

                </table>
            
                <hr>
            
                <div style="justify-content: space-between; align-items: center; flex-direction: row; display:flex;">
                    <div
                        style="justify-content: space-between; align-items: flex-start; flex-direction: row; display:flex; width: 50%; margin-right: 2%;">
                        <div style="">
                            <p style="margin: 0; font-weight: bold; font-family: sans-serif;">Collected By: </p>
                        </div>
                        <div style="flex-wrap: wrap; display: flex; width: 40%;">
                            <p style="margin: 0; flex-wrap: wrap; display:flex; text-align: left; font-family: sans-serif;">
                            ${reportOverviewData.result.name}   ${
      reportOverviewData.result.surname
    }  ${reportOverviewData.result.employee_id}</p>
                        </div>
                    </div>
                    <div
                        style="justify-content: space-between; align-items: flex-start; flex-direction: row; display:flex; width: 50%; margin-right: 2%;">
                        <div style="">
                            <p style="margin: 0; font-weight: bold; font-family: sans-serif;"></p>
                        </div>
                        <div style="width: auto; flex-wrap: wrap; display: flex; width: 40%;">
                            <p style="margin: 0; flex-wrap: wrap; display:flex; text-align: left; font-family: sans-serif;"></p>
                        </div>
                    </div>
                </div>
            
                <hr />
            
                <div style="justify-content: space-between; align-items: center; flex-direction: row; display:flex;">
                    <div
                        style="justify-content: space-between; align-items: flex-start; flex-direction: row; display:flex; width: 50%; margin-right: 2%;">
                        <div style="">
                            <p style="margin: 0; font-weight: bold; font-family: sans-serif;"></p>
                        </div>
                        <div style="flex-wrap: wrap; display: flex; width: 40%;">
                            <p style="margin: 0; flex-wrap: wrap; display:flex; text-align: left; font-family: sans-serif;"></p>
                        </div>
                    </div>
                    <div
                        style="justify-content: space-between; align-items: flex-start; flex-direction: row; display:flex; width: 50%; margin-right: 2%;">
                        <div style="">
                            <p style="margin: 0; font-weight: bold; font-family: sans-serif;">Total(Rs): </p>
                        </div>
                        <div style="width: auto; flex-wrap: wrap; display: flex; width: 40%;">
                            <p style="margin: 0; flex-wrap: wrap; display:flex; text-align: left; font-family: sans-serif;">
                                ${reportOverviewData.result.total}</p>
                        </div>
                    </div>
                </div>
                <div style="justify-content: space-between; align-items: center; flex-direction: row; display:flex;">
                    <div
                        style="justify-content: space-between; align-items: flex-start; flex-direction: row; display:flex; width: 50%; margin-right: 2%;">
                        <div style="">
                            <p style="margin: 0; font-weight: bold; font-family: sans-serif;"></p>
                        </div>
                        <div style="flex-wrap: wrap; display: flex; width: 40%;">
                            <p style="margin: 0; flex-wrap: wrap; display:flex; text-align: left; font-family: sans-serif;"></p>
                        </div>
                    </div>
                    <div
                        style="justify-content: space-between; align-items: flex-start; flex-direction: row; display:flex; width: 50%; margin-right: 2%;">
                        <div style="">
                            <p style="margin: 0; font-weight: bold; font-family: sans-serif;">Discount(Rs): </p>
                        </div>
                        <div style="width: auto; flex-wrap: wrap; display: flex; width: 40%;">
                            <p style="margin: 0; flex-wrap: wrap; display:flex; text-align: left; font-family: sans-serif;">
                                ${reportOverviewData.result.discount}</p>
                        </div>
                    </div>
                </div>
                <div style="justify-content: space-between; align-items: center; flex-direction: row; display:flex;">
                    <div
                        style="justify-content: space-between; align-items: flex-start; flex-direction: row; display:flex; width: 50%; margin-right: 2%;">
                        <div style="">
                            <p style="margin: 0; font-weight: bold; font-family: sans-serif;"></p>
                        </div>
                        <div style="flex-wrap: wrap; display: flex; width: 40%;">
                            <p style="margin: 0; flex-wrap: wrap; display:flex; text-align: left; font-family: sans-serif;"></p>
                        </div>
                    </div>
                    <div
                        style="justify-content: space-between; align-items: flex-start; flex-direction: row; display:flex; width: 50%; margin-right: 2%;">
                        <div style="">
                            <p style="margin: 0; font-weight: bold; font-family: sans-serif;">Tax(Rs): </p>
                        </div>
                        <div style="width: auto; flex-wrap: wrap; display: flex; width: 40%;">
                            <p style="margin: 0; flex-wrap: wrap; display:flex; text-align: left; font-family: sans-serif;">
                                ${reportOverviewData.result.tax}</p>
                        </div>
                    </div>
                </div>
                <div style="justify-content: space-between; align-items: center; flex-direction: row; display:flex;">
                    <div
                        style="justify-content: space-between; align-items: flex-start; flex-direction: row; display:flex; width: 50%; margin-right: 2%;">
                        <div style="">
                            <p style="margin: 0; font-weight: bold; font-family: sans-serif;"></p>
                        </div>
                        <div style="flex-wrap: wrap; display: flex; width: 40%;">
                            <p style="margin: 0; flex-wrap: wrap; display:flex; text-align: left; font-family: sans-serif;"></p>
                        </div>
                    </div>
                    <div
                        style="justify-content: space-between; align-items: flex-start; flex-direction: row; display:flex; width: 50%; margin-right: 2%;">
                        <div style="">
                            <p style="margin: 0; font-weight: bold; font-family: sans-serif;">Net Amount(Rs): </p>
                        </div>
                        <div style="width: auto; flex-wrap: wrap; display: flex; width: 40%;">
                            <p style="margin: 0; flex-wrap: wrap; display:flex; text-align: left; font-family: sans-serif;">
                                ${reportOverviewData.result.net_amount}</p>
                        </div>
                    </div>
                </div>
                <div style="justify-content: space-between; align-items: center; flex-direction: row; display:flex;">
                    <div
                        style="justify-content: space-between; align-items: flex-start; flex-direction: row; display:flex; width: 50%; margin-right: 2%;">
                        <div style="">
                            <p style="margin: 0; font-weight: bold; font-family: sans-serif;"></p>
                        </div>
                        <div style="flex-wrap: wrap; display: flex; width: 40%;">
                            <p style="margin: 0; flex-wrap: wrap; display:flex; text-align: left; font-family: sans-serif;"></p>
                        </div>
                    </div>
                    <div
                        style="justify-content: space-between; align-items: flex-start; flex-direction: row; display:flex; width: 50%; margin-right: 2%;">
                        <div style="">
                            <p style="margin: 0; font-weight: bold; font-family: sans-serif;">Paid Amount(Rs): </p>
                        </div>
                        <div style="width: auto; flex-wrap: wrap; display: flex; width: 40%;">
                            <p style="margin: 0; flex-wrap: wrap; display:flex; text-align: left; font-family: sans-serif;">
                                ${reportOverviewData.result.paid_amount}</p>
                        </div>
                    </div>
                </div>
                <div style="justify-content: space-between; align-items: center; flex-direction: row; display:flex;">
                    <div
                        style="justify-content: space-between; align-items: flex-start; flex-direction: row; display:flex; width: 50%; margin-right: 2%;">
                        <div style="">
                            <p style="margin: 0; font-weight: bold; font-family: sans-serif;"></p>
                        </div>
                        <div style="flex-wrap: wrap; display: flex; width: 40%;">
                            <p style="margin: 0; flex-wrap: wrap; display:flex; text-align: left; font-family: sans-serif;"></p>
                        </div>
                    </div>
                    <div
                        style="justify-content: space-between; align-items: flex-start; flex-direction: row; display:flex; width: 50%; margin-right: 2%;">
                        <div style="">
                            <p style="margin: 0; font-weight: bold; font-family: sans-serif;">Refund Amount(Rs): </p>
                        </div>
                        <div style="width: auto; flex-wrap: wrap; display: flex; width: 40%;">
                            <p style="margin: 0; flex-wrap: wrap; display:flex; text-align: left; font-family: sans-serif;">
                                ${reportOverviewData.result.refund_amount}</p>
                        </div>
                    </div>
                </div>
                <div style="justify-content: space-between; align-items: center; flex-direction: row; display:flex;">
                    <div
                        style="justify-content: space-between; align-items: flex-start; flex-direction: row; display:flex; width: 50%; margin-right: 2%;">
                        <div style="">
                            <p style="margin: 0; font-weight: bold; font-family: sans-serif;"></p>
                        </div>
                        <div style="flex-wrap: wrap; display: flex; width: 40%;">
                            <p style="margin: 0; flex-wrap: wrap; display:flex; text-align: left; font-family: sans-serif;"></p>
                        </div>
                    </div>
                    <div
                        style="justify-content: space-between; align-items: flex-start; flex-direction: row; display:flex; width: 50%; margin-right: 2%;">
                        <div style="">
                            <p style="margin: 0; font-weight: bold; font-family: sans-serif;">Due Amount(Rs): </p>
                        </div>
                        <div style="width: auto; flex-wrap: wrap; display: flex; width: 40%;">
                            <p style="margin: 0; flex-wrap: wrap; display:flex; text-align: left; font-family: sans-serif;">
                                ${
                                  reportOverviewData.result.paid_amount > "0"
                                    ? "0.00"
                                    : reportOverviewData.result.net_amount
                                }</p>
                        </div>
                    </div>
                </div>
            
            
            
            </body>
            
            </html>
              `;

    await htmlStringToPdf(html);
  };

  // const pdfUrl = `https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf`;
  // const downloadPdf = () => {
  //   // Create a link element
  //   const link = document.createElement("a");
  //   // Set the href attribute to the PDF URL
  //   link.href = pdfUrl;
  //   link.target = "_blank";
  //   // Set the download attribute with the desired file name
  //   link.download = "downloaded-file.pdf";
  //   // Append the link to the document
  //   document.body.appendChild(link);
  //   // Trigger a click on the link to start the download
  //   link.click();
  //   // Remove the link from the document
  //   document.body.removeChild(link);
  // };

  return (
    <div>
      <Header {...props} />
      <div className="breadcrumb-bar-two">
        <div className="container">
          <div className="row align-items-center inner-banner">
            <div className="col-md-12 col-12 text-center">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <h2 className="breadcrumb-title">Medical Details</h2>

                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/index">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Medical Details
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
              <StickyBox offsetTop={20} offsetBottom={20}>
                <DashboardSidebar />
              </StickyBox>
            </div>
            <div className="col-md-7 col-lg-8 col-xl-9">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title float-start">
                        Medical details
                      </h4>
                      <Link
                        to="/uploadRx"
                        // to="#modal_medical_form"
                        className="btn btn-primary float-end"
                        onClick={() => setShow(true)}
                      >
                        Add Prescription
                      </Link>
                    </div>

                    <div className="card card-table mb-0">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table table-hover table-center mb-0">
                            <thead>
                              <tr>
                                <th>Date </th>

                                <th>Created by </th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.length > 0
                                ? data?.map((item, index) => (
                                    <tr key={index}>
                                      <td>{item?.date}</td>

                                      <td>
                                        <h2 className="table-avatar">
                                          Dr. {item.doctor_name}
                                        </h2>
                                      </td>
                                      <td className="text-end">
                                        <div className="table-action">
                                          <Link
                                            to="#0"
                                            className="btn btn-sm bg-primary-light"
                                            target="blank"
                                            onClick={() =>
                                              fetchParticularPharmacyReports(
                                                item?.id
                                              )
                                            }
                                          >
                                            <i className="fas fa-print"></i>{" "}
                                            Print
                                          </Link>
                                          &nbsp;
                                          <Link
                                            to="#0"
                                            className="btn btn-sm bg-info-light"
                                          >
                                            <i className="far fa-eye"></i> View
                                          </Link>
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                : null}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                    {/* /Dependent Tab */}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        style={{ marginTop: "75px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="modal-title">Add new data</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            <div>
              <form
                action="#"
                encType="multipart/form-data"
                autoComplete="off"
                method="post"
              >
                <div className="modal-body">
                  <input type="hidden" defaultValue name="id" />
                  <input type="hidden" defaultValue="insert" name="method" />
                  <div className="form-group">
                    <label className="control-label mb-10">
                      {" "}
                      BMI <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="bmi"
                      className="form-control"
                      defaultValue="23.7"
                    />
                  </div>
                  <div className="form-group">
                    <label className="control-label mb-10">Heart rate </label>
                    <input
                      type="text"
                      name="hr"
                      className="form-control"
                      defaultValue={89}
                    />
                  </div>
                  <div className="form-group">
                    <label className="control-label mb-10">Weight</label>
                    <input
                      type="text"
                      name="Weight"
                      className="form-control"
                      defaultValue={74}
                    />
                  </div>
                  <div className="form-group">
                    <label className="control-label mb-10">Fbc</label>
                    <input
                      type="text"
                      name="Fbc"
                      className="form-control"
                      defaultValue={140}
                    />
                  </div>
                  <div className="form-group">
                    <label className="control-label mb-10">Created Date </label>
                    <input
                      type="text"
                      name="dob"
                      id="editdob"
                      defaultValue="11/11/2019"
                      readOnly
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="modal-footer text-center">
                  <button
                    type="submit"
                    className="btn btn-outline btn-success "
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Footer {...props} />
    </div>
  );
};

export default MedicalDetails;
