import React, { useState } from "react";
import Header from "../../header";

import camera from "../../../assets/images/icons/camera.svg";
import { useAppData } from "../../../../hooks/AppDataContext";
import { PostIpdBasicApi, PostRxApi } from "../../../../apis/UploadRxApis";
import { useHistory, Link, useLocation } from "react-router-dom";
import { logo } from "../../imagepath";

const UploadRx = (props) => {
  const history = useHistory();
  const [selectedImage, setSelectedImage] = useState([]);
  const [selectedImage1, setSelectedImage1] = useState([]);
  const { appData } = useAppData();
  const location = useLocation();
  const name = new URLSearchParams(location.search).get("n");
  const surname = new URLSearchParams(location.search).get("s");

  const handelUploadRx = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage1((prevImg) => [...prevImg, reader.result]);
      };
      reader.readAsDataURL(file);
    }

    const files = event.target.files;
    // Use spread operator to concatenate the new files to the existing array
    setSelectedImage((prevFiles) => [...prevFiles, ...files]);
  };

  // console.log(name, surname);

  const handleRemove = (index) => {
    const updatedList = [...selectedImage];
    updatedList.splice(index, 1); // 1 is return because to remove only one index if 2 return it will remove next 2 indexes
    setSelectedImage(updatedList);
    setSelectedImage1(updatedList);
  };

  // const PostRx = () => {
  //   history.push("/pharmacy-list");
  // };

  const PostRx = async () => {
    const data = {
      p_id: appData?.user?.id,
      d_id: appData?.scanner?.d_id,
    };

    const req = await PostIpdBasicApi(data);
    console.log(req);
    try {
      const formData = new FormData();
      formData.append("ipd_id", req?.inserted_id);
      formData.append("prescribe_by", appData?.scanner?.d_id);

      // Append each image file to the FormData
      selectedImage.forEach((file) => {
        formData.append(`imgUrl`, file);
      });

      // const response = await axios.post(
      //   "http://192.168.1.9/cerdocpatientapi/api/uploadImageRx",
      //   formData,
      //   {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }
      // );
      const request = await PostRxApi(formData);

      console.log(request);
      if (request?.status == "success") {
        history.push({
          pathname: "/pharmacy-list",
          search: `?id=${request?.insert_id}`,
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
    // if (req?.status == "success") {
    //   // const data_1 = {

    //   //   prescribe_by: appData?.scanner?.d_id,
    //   //   imgUrl: selectedImage,
    //   // };
    //   const formData = new FormData();
    //   formData.append("ipd_id", req?.inserted_id);
    //   formData.append("prescribe_by", appData?.scanner?.d_id);

    //   // Append each image file to the FormData
    //   selectedImage.forEach((file) => {
    //     formData.append(`imgUrl`, file);
    //   });
    //   console.log("data for uploadRx", formData);

    //   const request = await PostRxApi(formData);
    //   if (request?.msg == "Prescription uploaded sucessfully") {
    //     history.push("/pharmacy-list");
    //   }
    //   console.log("uploadRx", request);
    // }
    console.log("ipd_basic", req);
  };

  // const PostRx = async () => {
  //   const data = {
  //     p_id: appData?.user?.id,
  //     d_id: appData?.scanner?.d_id,
  //     files: selectedImage,
  //   };

  //   const req = await PostRxApi(data);
  //   if (req?.msg == "Prescription uploaded sucessfully") {
  //     history.push("/pharmacy-list");
  //   }
  //   console.log("heloo", req);
  // };

  return (
    <div>
      <Header {...props} />

      <div className="content" style={{ display: "flex", minHeight: "85vh" }}>
        <div className="container-fluid">
          <div
            className="section-header-three text-center aos"
            data-aos="fade-up"
            style={{ paddingTop: "10vh" }}
          >
            <h2>Click to upload prescriptions</h2>
            <p className="fs-6">
              Capture your prescription for {name} {surname}.
            </p>
          </div>
          <form onSubmit={PostRx}>
            {selectedImage1?.length > 0 ? (
              <div className="row">
                {selectedImage1?.map((image, index) => (
                  <div className="profile-pic-upload" key={index}>
                    <div className="row justify-content-between">
                      <button
                        type="button"
                        className="btn-close align-items-end"
                        aria-label="Close"
                        onClick={() => handleRemove(index)}
                      >
                        <span aria-hidden="true"></span>
                      </button>
                      <div
                        className="rounded-pill "
                        style={{
                          display: "flex",
                          backgroundColor: "#20c997",
                          height: "20px",
                          width: "20px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <a style={{ color: "white" }}>{index + 1}</a>
                      </div>
                    </div>
                    <img
                      src={image}
                      id="prof-avatar"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                ))}
                <div className="profile-pic-upload">
                  <div className="cam-col">
                    <img
                      src={camera}
                      id="prof-avatar"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <span className="text-center">Upload Next Page</span>
                  <input
                    type="file"
                    id="profile_image"
                    name="profile_image"
                    onChange={handelUploadRx}
                  />
                </div>
              </div>
            ) : (
              <div className="profile-pic-upload">
                <div className="cam-col">
                  <img
                    src={camera}
                    id="prof-avatar"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <span className="text-center">Upload Your Prescription</span>
                <input
                  type="file"
                  id="profile_image"
                  name="profile_image"
                  onChange={handelUploadRx}
                />
              </div>
            )}
            {selectedImage?.length > 0 ? (
              <div
                className="align-items-center justify-content-center"
                style={{ display: "flex" }}
                //  style={{ display :'flex', justifyContent:'center', alignItems:'center' }}
              >
                <div className="mt-5 w-50">
                  <a
                    type="submit"
                    className="btn btn-primary w-100 btn-lg login-btn step1_submit"
                    onClick={() => PostRx()}
                  >
                    Find Nearest Pharmacy{" "}
                  </a>
                </div>
              </div>
            ) : null}
          </form>
        </div>
      </div>

      <footer
        className="footer footer-one"
        // style={{ padding: !showCopyRightBar ? 0 : 50 }}
        style={
          {
            // position: "absolute",
            // left: 0,
            // bottom: 0,
            // right: 0,
          }
        }
      >
        <div className="footer-bottom">
          <div className="container">
            {/* Copyright */}
            <div className="copyright">
              <div className="navbar-header">
                <Link to="/index" className="navbar-brand logo">
                  <img src={logo} alt="logo" className="img-fluid" />
                </Link>
              </div>

              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <div className="copyright-text">
                    <p className="mb-0">
                      {" "}
                      Copyright © 2015{" "}
                      <a href="https://certumventures.in/" target="blank">
                        certumventures.
                      </a>{" "}
                      All Rights Reserved
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6">
                  {/* Copyright Menu */}
                  <div className="copyright-menu">
                    <ul className="policy-menu">
                      <li>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/terms">Terms and Conditions</Link>
                      </li>
                    </ul>
                  </div>
                  {/* /Copyright Menu */}
                </div>
              </div>
            </div>
            {/* /Copyright */}
          </div>
        </div>
      </footer>
    </div>
  );
};

export default UploadRx;
