import { apiphpClient } from "./configs/axiosConfig";

export const PostIpdBasicApi = async (data) => {
  const item = {
    p_id: data?.p_id,
    d_id: data?.d_id,
  };
  let responseToBeSent;

  await apiphpClient
    .post(`/api/storeipddetails`, item)
    .then(async (res) => {
      responseToBeSent = res?.data;
    })
    .catch((err) => {
      console.log("Error in upload Rx ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};

export const PostRxApi = async (data) => {
  let responseToBeSent;

  await apiphpClient
    .post(`/api/uploadImageRx`, data, {
      // headers: {
      //   "Content-Type": "application/json",
      // },
    })
    .then(async (res) => {
      responseToBeSent = res?.data;
    })
    .catch((err) => {
      console.log("Error in upload Rx ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};

export const GetPharmaList = async (data) => {
  let responseToBeSent;
  await apiphpClient
    .get(`/api/getpharmacies?lat=${data.lat}&lon=${data.lng}`)
    .then((res) => {
      responseToBeSent = res?.data;
    })
    .catch((err) => {
      console.log("Error in Getting Pharma List ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};

export const SendToPharmacy = async (data) => {
  let responseToBeSent;

  await apiphpClient
    .post(`/api/sendprestophar`, data)
    .then(async (res) => {
      console.log(res);
      responseToBeSent = res?.data;
    })
    .catch((err) => {
      console.log("Error in Sending Rx to pharamcy====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};
