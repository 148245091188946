import React, { useState, useEffect } from "react";
// import { useHistory } from "react-router-dom";
import loginBanner from "../../assets/images/doc-slide-1.png";
import { Link, useHistory } from "react-router-dom";
import Header from "../header";
import Footer from "../footer";
import { PatientLogin } from "../../../apis/authApis";
import { useAppData } from "../../../hooks/AppDataContext";
// const config = "//";

const LoginContainer = (props) => {
  // const history = useHistory();
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [err, setErr] = useState("");
  const { appData, updateAppData } = useAppData();

  const Login = async () => {
    const data = {
      mobileno: phone,
      password: password,
    };

    if (phone == "") {
      setErr("Email must be specified");
    } else if (password == "") {
      setErr("Password must be specified");
    } else {
      let req = await PatientLogin(data);
      // console.log("28==========>",req);
      if (req?.data?.id) {
        localStorage.setItem("user", JSON.stringify(req.data));
        history.push({
          pathname: "/uploadRx",
          search: `?name=patient`,
        });
        updateAppData({ user: req.data, isLoggedIn: true });
      } else {
        setErr(req.message);
      }
    }
  };

  const history = useHistory();

  const navigatetoScanner = () => {
    history.push({
      pathname: `/qr/${appData?.scanner?.pin}/${appData?.scanner?.d_id}`,
      // search: `?pin=${appData?.scanner?.pin}&d_id=${appData?.scanner?.d_id}`,
    });
  };

  useEffect(() => {
    document.body.classList.add("account-page");

    return () => document.body.classList.remove("account-page");
  }, []);

  return (
    <>
      <Header {...props} />

      <>
        {/* Page Content */}
        <div className="content top-space">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                {/* Login Tab Content */}
                <div className="account-content">
                  <div className="row align-items-center justify-content-center">
                    <div className="col-md-7 col-lg-6 login-left">
                      <img
                        src={loginBanner}
                        className="img-fluid"
                        alt="Doccure Login"
                      />
                    </div>
                    <div className="col-md-12 col-lg-6 login-right">
                      <div className="login-header">
                        <h3>
                          PATIENT <span>LOGIN</span>
                        </h3>
                      </div>
                      <form>
                        <div className="form-group">
                          <label className="focus-label">
                            Phone<span className="text-danger">*</span>
                          </label>
                          <input
                            type="tel"
                            className="form-control"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label className="focus-label">
                            Password<span className="text-danger">*</span>
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>

                        {err?.length > 0 ? (
                          <div className="text-danger" role="alert">
                            <strong>Warning!</strong> {err}
                          </div>
                        ) : null}
                        <div className="text-end">
                          <Link
                            className="forgot-link"
                            to="/pages/forgot-password"
                          >
                            Forgot Password ?
                          </Link>
                        </div>

                        <a
                          // to="/patient/dashboard"
                          className="btn btn-primary w-100 btn-lg login-btn"
                          type="submit"
                          onClick={() => Login()}
                        >
                          Login
                        </a>
                        <div className="login-or">
                          <span className="or-line" />
                          <span className="span-or">or</span>
                        </div>

                        <div className="text-center dont-have">
                          Don’t have an account?{" "}
                          <a onClick={() => navigatetoScanner()}>Register</a>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                {/* /Login Tab Content */}
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </>

      <Footer {...props} />
    </>
  );
};

export default LoginContainer;
