import React from "react";
import {
  banner_img3,
  checkBox,
  down_arrow_img,
  feedback_six,
  // services_six_1,
} from "../../imagepath";
import { Link } from "react-router-dom";
// import FeatherIcon from "feather-icons-react/build/FeatherIcon";
// import DatePicker from "react-datepicker";

// import "react-datepicker/dist/react-datepicker.css";
function Homebanner() {
  // const config = "/";
  // const [selectedDate, setSelectedDate] = useState(null);

  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };
  // const history = useHistory();

  // const navigatetoScanner = () => {
  //   history.push({
  //     pathname: "/qr",
  //     search: "?pin=560085&d_id=212",
  //   });
  // };
  return (
    <>
      <>
        <section className="banner-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="banner-content aos" data-aos="fade-up">
                  <h1>
                    <span>CerDoc</span>
                  </h1>

                  <h1>Earn Health Everyday</h1>
                  <p>
                    A Healthcare Ecosystem To Make Healthcare Affordable &
                    Accessible.
                  </p>
                  <Link to={`patient/search-doctor1`} className="btn">
                    Start a Consult
                  </Link>
                  <div className="banner-arrow-img">
                    <img src={down_arrow_img} className="img-fluid" alt="" />
                  </div>
                </div>
                {/* <div className="search-box-one aos" data-aos="fade-up">
                  <form action={`${config}/patient/search-doctor1`}>
                    <div className="search-input search-line">
                      <i>
                        <FeatherIcon icon="search" style={{ width: "16px" }} />
                      </i>
                      <div className="form-group mb-0">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search doctors, clinics, hospitals, etc"
                        />
                      </div>
                    </div>
                    <div className="search-input search-map-line">
                      <i>
                        <FeatherIcon icon="map-pin" style={{ width: "16px" }} />
                      </i>
                      <div className="form-group mb-0">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Location"
                        />
                        <Link
                          className="current-loc-icon current_location"
                          to="#"
                        >
                          <i>
                            <FeatherIcon
                              icon="crosshair"
                              style={{ width: "16px" }}
                            />
                          </i>
                        </Link>
                      </div>
                    </div>
                    <div className="search-input search-calendar-line">
                      <i>
                        <FeatherIcon
                          icon={"calendar"}
                          // style={{ width: "16px" }}
                        />
                      </i>
                      <div className="form-group mb-0">
                        <DatePicker
                          className="form-control datetimepicker"
                          selected={selectedDate}
                          onChange={handleDateChange}
                          placeholderText="Date"
                        />
                      </div>
                    </div>
                    <div className="form-search-btn">
                      <button className="btn" type="submit">
                        Search
                      </button>
                    </div>
                  </form>
                </div> */}
                {/* <i>
                  <i className="feather-map-pin">
                    <i className="feather-crosshair">
                      <i className="feather-calendar">
                        
                      </i>
                    </i>
                  </i>
                </i> */}
              </div>
              <div className="col-lg-6">
                <div className="banner-img aos" data-aos="fade-up">
                  {/* <img src={services_six_1} className="img-fluid" alt="" /> */}
                  <img src={feedback_six} className="img-fluid" alt="" />

                  <div className="banner-img1">
                    {/* <Link to="/qr/789098/212"> */}
                    <div className="checkContainer">
                      <img
                        src={checkBox}
                        className="img-fluid"
                        alt=""
                        // onClick={() => navigatetoScanner()}
                      />
                      <a>Preventive Care</a>
                    </div>
                    {/* </Link> */}
                  </div>
                  <div className="banner-img4">
                    {/* <Link to="/qr/789098/212"> */}
                    <div className="checkContainer">
                      <img src={checkBox} className="img-fluid" alt="" />
                      <a>Primary Care</a>
                    </div>
                    {/* </Link> */}
                  </div>
                  <div className="banner-img2">
                    {/* <img src={banner_img2} className="img-fluid" alt="" /> */}
                    <div className="centered-profile-container">
                      <img
                        src={
                          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFslsg55Xpy1HKlCqTSWKXSo8h3WdevAwRjmYnXi5-1g&s"
                        }
                        style={{
                          borderRadius: 50,
                          height: 50,
                          width: 50,
                          objectFit: "fill",
                        }}
                        alt="Profile"
                        className="profile-image"
                      />

                      <a>Dr. Vivek BR</a>
                      <p>Cardiology</p>

                      <button
                        className="btn btn-block w-100 btn-outline-info"
                        disabled
                      >
                        Book now
                      </button>
                    </div>
                  </div>
                  <div className="banner-img3">
                    <img src={banner_img3} className="img-fluid" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  );
}

export default Homebanner;
