import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { feedback_six } from "../imagepath";
import Header from "../header";
import Footer from "../footer";
import "aos/dist/aos.css";

const Register = (props) => {
  // const history = useHistory();
  const [fName, setfName] = useState("");
  const [lName, setlName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    document.body.classList.add("account-page");

    return () => document.body.classList.remove("account-page");
  }, []);

  return (
    <>
      <Header {...props} />

      <>
        {/* Page Content */}
        <div className="content top-space">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                {/* Register Content */}
                <div className="account-content">
                  <div className="row align-items-center justify-content-center">
                    <div className="col-lg-6">
                      <div className="client-inner-main">
                        <img
                          src={feedback_six}
                          alt="image"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6 login-right">
                      <div className="login-header">
                        <h3>
                          Patient Register{" "}
                          {/* <Link to="/doctor/doctor-register">
                            Are you a Doctor?
                          </Link> */}
                        </h3>
                      </div>
                      {/* Register Form */}
                      <form>
                        <div className="row row-cols-2">
                          <div className="form-group form-focus">
                            <input
                              type="text"
                              className="form-control floating"
                              value={fName}
                              onChange={(e) => setfName(e.target.value)}
                            />
                            <label
                              className="focus-label"
                              style={{ paddingLeft: 10 }}
                            >
                              Name
                            </label>
                          </div>
                          <div className="form-group form-focus">
                            <input
                              type="text"
                              className="form-control floating"
                              value={lName}
                              onChange={(e) => setlName(e.target.value)}
                            />
                            <label
                              className="focus-label"
                              style={{ paddingLeft: 10 }}
                            >
                              Surname
                            </label>
                          </div>
                        </div>
                        <div className="form-group form-focus">
                          <input
                            type="text"
                            className="form-control floating"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                          <label className="focus-label">Mobile Number</label>
                        </div>
                        <div className="form-group form-focus">
                          <input
                            type="email"
                            className="form-control floating"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <label className="focus-label">Email</label>
                        </div>
                        <div className="text-end">
                          <Link className="forgot-link" to="/login">
                            Already have an account?
                          </Link>{" "}
                          Login
                        </div>
                        <Link
                          to="/patient/patientregisterstep-1"
                          className="btn btn-primary w-100 btn-lg login-btn"
                          type="submit"
                        >
                          Signup
                        </Link>
                        {/* <div className="login-or">
                          <span className="or-line" />
                          <span className="span-or">or</span>
                        </div>
                        <div className="align-center">
                        
                          <div className="col-6">
                            <Link to="/patient/dashboard" className="btn btn-google w-100">
                              <i className="fab fa-google me-1" /> Login
                            </Link>
                          </div>
                        </div> */}
                      </form>
                      {/* /Register Form */}
                    </div>
                  </div>
                </div>
                {/* /Register Content */}
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </>

      <Footer {...props} />
    </>
  );
};

export default Register;
