
export { default as IMG01} from '../../../assets/images/doctor-thumb-01.jpg';
export { default as IMG02} from '../../../assets/images/doctor-thumb-02.jpg';
export { default as IMG03} from '../../../assets/images/doctor-thumb-03.jpg';
export { default as IMG04} from '../../../assets/images/doctor-thumb-04.jpg';
export { default as IMG05} from '../../../assets/images/doctor-thumb-05.jpg';

export { default as IMG06} from '../../../assets/images/doctor-thumb-06.jpg';
export { default as IMG07} from '../../../assets/images/doctor-thumb-07.jpg';
export { default as IMG08} from '../../../assets/images/doctor-thumb-08.jpg';
export { default as IMG09} from '../../../assets/images/doctor-thumb-09.jpg';
export { default as IMG10} from '../../../assets/images/doctor-thumb-10.jpg';
